.contact-container {
    min-height: calc(100vh - 300px);
    display: flex;
    @include down($tablet) {
        flex-direction: column-reverse;
        align-items: center;
    }

    .me-or-company {
        width: 50%;
        padding: 32px;
        height: fit-content;
        @include down($tablet) {
            width: 85%;
            padding: 20px;
        }
        @include down($mobile) {
            width: 100%;
        }
    }

    .contact-form-container {
        width: 50%;
        padding: 32px;
        max-width: 550px;
        @include down($tablet) {
            width: 100%;
            margin-bottom: 40px;
            padding: 20px;
        }
        @include down($tablet) {
            width: 75%;
        }
        @include down($mobile-xl) {
            width: 100%;
        }

        .heading {
            @include down($tablet) {
                padding-left: 0;
            }
        }

        .message-text {
            font-size: 16px;
            margin-bottom: 32px;
            @include down($tablet) {
                margin-bottom: 24px;
            }
        }

        .contact-form {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .form-field {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 24px;

            &:last-of-type {
                margin-bottom: 32px;
            }

            span {
                font-size: 16px;
                margin-bottom: 8px;
                @include down($tablet) {
                    margin-bottom: 4px;
                }
            }

            input,
            textarea {
                padding: 12px;
                border: none;
                border-bottom: 1px solid $black;
                outline: none;
                font-size: 18px;
                cursor: pointer;

                &:hover {
                    border-bottom: 2px solid $black;
                }

                &.validated {
                    border-bottom: 1px solid $green;
                }
            }

            input {
                @include down($tablet) {
                    padding: 8px;
                }
                &.invalid {
                    border-bottom: 1px solid $red;
                }
            }

            textarea {
                max-height: 100px;
                max-width: 100%;
                min-height: 100px;
                min-width: 100%;
                @include down($tablet) {
                    padding: 8px;
                    max-height: 85px;
                    min-height: 85px;
                }
            }
        }

        .submit-btn {
            cursor: pointer;
            border: none;
            background-color: transparent;
            text-transform: uppercase;
            letter-spacing: 0.75px;
        }
    }
}
