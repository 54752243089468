.resource-item {
    border: 1px solid lightgrey;
    border-radius: 8px;
    margin: 16px;
    box-shadow: 0px 0px 10px -5px $black;
    cursor: pointer;
    max-width: 300px;
    min-width: 300px;
    @include down($largest) {
        max-width: calc(20% - 32px);
        min-width: calc(20% - 32px);
    }
    @include down($very-large) {
        max-width: calc(25% - 32px);
        min-width: calc(25% - 32px);
    }
    @include down($large) {
        max-width: calc(33.33% - 32px);
        min-width: calc(33.33% - 32px);
    }
    @include down($large-tablet) {
        max-width: calc(50% - 32px);
        min-width: calc(50% - 32px);
        display: flex;
        flex-direction: column;
    }
    @include down($mobile-xl) {
        width: 100%;
        max-width: 350px;
        margin-left: 0;
        margin-right: 0;
    }

    .img {
        width: 100%;
        max-height: 185px;
        border-bottom: 1px solid lightgrey;
        border-radius: 4px 4px 0 0;
        @include down($mobile-xl) {
            max-height: 200px;
        }
    }

    .copy-container {
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 185px);
    }

    .name {
        margin-bottom: 8px;
    }

    .text {
        margin-bottom: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        font-size: 14px;
        @include down($tablet) {
            font-size: 12px;
        }
    }
    
    .bottom-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span.date {
            margin-bottom: 0;
            color: $black;
            font-size: 14px;
            @include down($tablet) {
                font-size: 12px;
            }
        }
    }
}

