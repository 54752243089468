.icon-item-container, .table-of-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .icon-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 50px;
        min-width: 16.66vw;
        opacity: 0.25;
        &.available {
            opacity: 1;
            cursor: pointer;
        }
        .icon-name {
            font-size: 16px;
            @include down($tablet) {
                font-size: 12px;
            }
        }
        @include down($very-large) {
            min-width: 20%;
        }
        @include down($desktop) {
            min-width: 25%;
        }
        @include down($tablet) {
            min-width: 33.33%;
        }
        @include down($mobile) {
            min-width: 50%;
        }
        img {
            height: 100px;
            margin-bottom: 10px;
            @include down($tablet) {
                height: 90px;
            }
            @include down($mobile-xl) {
                height: 85px;
            }
            @include down($mobile) {
                height: 75px;
            }
        }
    }
}
