.generic-score-sheet,
.silent-auction-score-sheet {
    strong {
        margin-bottom: 12px;
        display: block;
        font-size: 16px;

        @include down($mobile) {
            margin-bottom: 8px;
        }
    }

    .default-input {
        border: none;
        padding: 8px;
        outline: none;
        display: block;
        font-size: 16px;
        width: 350px;
        &::placeholder {
            color: $gray;
        }
        @include down($mobile) {
            width: 100%;
        }
    }

    .default-btn {
        background: $green;
    }

    .close-logo {
        height: 16px;
        width: 16px;
        margin-left: 8px;
    }

    .duplicate-logo {
        height: 24px;
        width: 24px;
        margin-left: 8px;
    }

    .plus-logo {
        height: 18px;
        width: 18px;
        margin-left: 6px;
        &.disabled {
            opacity: 0.25;
        }
    }

    .tab-section-container {
        padding: 24px 8px;
    }

    .rooms-container {
        .existing-room {
            border-bottom: none;
            margin-bottom: 0;
            &:focus {
                border-bottom: 1px solid $black;
            }
        }

        .room {
            font-size: 16px;
            margin-bottom: 4px;
            display: flex;
            align-items: center;

            .selected-room {
                color: $blue;
            }

            .default-input {
                width: calc(100% - 48px);
                &:focus {
                    border-bottom: 1px solid $black;
                }
            }

            &.new-room {
                .default-input {
                    width: calc(100% - 36px);
                }
            }
        }

        .no-rooms {
            margin-bottom: 16px;
        }
    }

    .game-details-container {
        input {
            padding: 8px;
        }

        .game-name-input {
            border-bottom: none;
            margin-bottom: 0;
            &:focus {
                border-bottom: 1px solid $black;
            }
        }

        .existing-player {
            border-bottom: none;
            margin-bottom: 0;
            &:focus {
                border-bottom: 1px solid $black;
            }
        }

        .game-name,
        .game-rounds,
        .game-players,
        .game-bonus-per-round,
        .game-final-bonus {
            margin-bottom: 32px;
            font-size: 16px;
            @include down($mobile) {
                margin-bottom: 24px;
            }
        }

        .game-rounds {
            .default-input {
                &:focus {
                    border-bottom: 1px solid $black;
                }
            }
        }

        .game-players {
            .player {
                font-size: 16px;
                margin-bottom: 4px;
                display: flex;
                align-items: center;

                .default-input {
                    width: calc(100% - 24px);
                    &:focus {
                        border-bottom: 1px solid $black;
                    }
                }
            }

            .no-player {
                margin-bottom: 12px;
            }
        }

        .radio-group {
            display: flex;
            align-items: center;

            label {
                &:not(:last-of-type) {
                    margin-right: 20px;
                }

                span {
                    margin-left: 10px;
                }
            }
        }
    }

    .score-sheet-container {
        .score-sheet-header {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 12px;
            @include down($mobile) {
                margin-bottom: 8px;
            }

            strong {
                margin-bottom: 0;
            }
        }
        .score-sheet-table {
            margin-bottom: 32px;
            overflow-x: scroll;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
            &::-webkit-scrollbar {
                display: none;
            }

            .item-row {
                display: flex;
                list-style: none;

                .empty-square {
                    width: 50px;
                    min-width: 50px;
                }

                li {
                    padding: 8px;
                    width: 100px;
                    min-width: 100px;
                    text-align: center;

                    &.player-name {
                        font-size: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                .item-round-number {
                    width: 50px;
                    min-width: 50px;
                    font-size: 10px;
                }

                &.score-row {
                    &:last-of-type {
                        .score {
                            border-bottom: 1px solid $black;
                        }
                    }
                    .score {
                        padding: 8px;
                        width: 100px;
                        min-width: 100px;
                        text-align: center;
                        border-top: 1px solid $black;
                        border-left: 1px solid $black;
                        font-size: 14px;
                        &:last-of-type {
                            border-right: 1px solid $black;
                        }

                        &.winner {
                            color: $blue;
                        }

                        .score-input {
                            width: 100%;
                            border: none;
                            outline: none;
                            text-align: center;
                        }
                    }
                }
            }
        }

        .score-sheet-summary {
            max-width: 300px;
            .score-sheet-summary-table-heading {
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                margin-bottom: 6px;
            }
            .score-sheet-summary-row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 8px;
                .score-sheet-left-side {
                    display: flex;
                    align-items: center;
                }
                .position-number-tag {
                    font-size: 20px;
                    width: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @include down($mobile) {
                        font-size: 16px;
                    }
                }
                .score-sheet-summary-players {
                    font-size: 16px;
                    margin-right: 4px;
                    @include down($mobile) {
                        font-size: 12px;
                    }
                }
                .score-sheet-summary-total {
                    font-size: 16px;
                    font-weight: bold;
                    width: unset;
                    @include down($mobile) {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

.silent-auction-score-sheet {
    .score-sheet-header {
        .score-sheet-summary-table-heading {
            & > span {
                &:first-of-type {
                    display: inline-flex;
                    min-width: 300px;
                    @include down($mobile) {
                        min-width: unset;
                    }
                }
            }
        }
        @include down($mobile) {
            flex-direction: column-reverse;
            gap: 24px;
        }
    }
    .score-sheet-container {
        .score-sheet-summary {
            margin-bottom: 32px;
            @include down($mobile) {
                max-width: 0;
                min-width: 100%;
            }
    
            .score-sheet-summary-players {
                min-width: 268px;
                @include down($mobile) {
                    min-width: unset;
                }
            }
        }
    }

    .silent-auction-table {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        margin-bottom: 32px;
        @include down($tablet) {
            row-gap: 24px;
        }   

        .add-auction-item-button {
            background: $skyblue;
            padding: 4px 8px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            color: $white;
        }

        .table-row-container {
            display: flex;
            flex-wrap: wrap;
            @include down($tablet) {
                flex-direction: column;
            }
        }

        .table-cell {
            padding: 8px 12px;
            border-top: 1px solid $black;
            border-bottom: 1px solid $black;
            border-right: 1px solid $black;
            display: flex;
            flex-direction: column;
            row-gap: 8px;
            @include down($tablet) {
                border-bottom: none;
                border-left: 1px solid $black;
                max-width: calc(100vw - 48px);
            }
        }

        .auction-item-name-cell {
            border-left: 1px solid $black;

            .auction-item-heading {
                font-size: 12px;
                margin-bottom: 0;
                @include down($tablet) {
                    font-size: 16px;
                    display: flex;
                }
            }

            .auction-item-name-selector {
                padding-block: 2px;
                max-width: 200px;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: pointer;
                font-size: 12px;
                @include down($tablet) {
                    font-size: 16px;
                    max-width: unquote("min(270px, 100%)");
                    padding-block: 4px;
                }
            }
        }

        .auction-bid-cell {
            .auction-bid-heading-container {
                display: flex;
                column-gap: 12px;

                .auction-bid-player-heading {
                    font-size: 12px;
                    min-width: 60px;
                    margin-bottom: 0;
                    @include down($tablet) {
                        font-size: 16px;
                        min-width: 64px;
                    }
                }

                .auction-bid-order-heading {
                    font-size: 12px;
                    min-width: 45px;
                    margin-bottom: 0;
                    @include down($tablet) {
                        font-size: 16px;
                        min-width: 55px;
                    }
                }

                .auction-bid-rolls-heading {
                    font-size: 12px;
                    min-width: 45px;
                    margin-bottom: 0;
                    @include down($tablet) {
                        font-size: 16px;
                        min-width: 55px;
                    }
                }

                .auction-bid-cost-heading {
                    font-size: 12px;
                    min-width: 50px;
                    margin-bottom: 0;
                    @include down($tablet) {
                        font-size: 16px;
                        min-width: 60px;
                    }
                }
            }

            .auction-bid-container {
                display: flex;
                align-items: center;
                column-gap: 12px;
                min-height: 19.5px;

                .player-value {
                    font-size: 12px;
                    min-width: 60px;
                    @include down($tablet) {
                        font-size: 16px;
                        min-width: 64px;
                    }
                }

                .order-value {
                    min-width: 47.5px;
                    font-size: 12px;
                    cursor: pointer;
                    @include down($tablet) {
                        font-size: 16px;
                        min-width: 55px;
                    }
                }

                .empty-order-value {
                    min-width: 47.5px;
                    @include down($tablet) {
                        min-width: 55px;
                    }
                }

                .bid-value {
                    min-width: 47.5px;
                    font-size: 12px;
                    cursor: pointer;
                    @include down($tablet) {
                        font-size: 16px;
                        min-width: 55px;
                    }
                }

                .empty-bid-value {
                    min-width: 47.5px;
                    @include down($tablet) {
                        font-size: 16px;
                        min-width: 55px;
                    }
                }

                .cost-value {
                    font-size: 12px;
                    min-width: 50px;
                    @include down($tablet) {
                        font-size: 16px;
                        min-width: 60px;
                    }
                }
            }
        }

        .auction-reward-cell {
            @include down($tablet) {
                border-bottom: 1px solid $black;
            }
            .auction-reward-heading {
                font-size: 12px;
                margin-bottom: 0;
                @include down($tablet) {
                    font-size: 16px;
                    display: flex;
                }
            }

            .auction-reward-container {
                display: flex;
                flex-direction: column;
                row-gap: 8px;

                .auction-reward-list-item {
                    display: flex;
                    align-items: center;
                    column-gap: 6px;
                    min-height: 19.5px;

                    .auction-reward-list-item-heading {
                        font-size: 12px;
                        width: 40px;
                        font-weight: 500;
                    }

                    .auction-reward-list-item-option-container {
                        display: flex;
                        column-gap: 8px;
                    }

                    .auction-or-reward-list-item-option-container {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 2px;
                    }

                    .auction-reward-list-item-option-value {
                        border: 1px solid black;
                        border-radius: 4px;
                        padding: 2px 4px;
                        min-width: 36px;
                        min-height: 19.5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        font-size: 12px;
                        @include down($tablet) {
                            font-size: 20px;
                            min-width: 45px;
                            min-height: 29px;
                        }
                    }

                    .default-selected-list-item-card {
                        color: $white;
                        background: $skyblue;
                        cursor: default;
                    }

                    .selected-list-item-card {
                        color: $white;
                        background: $skyblue;
                    }

                    .auction-or-reward-list-item-heading {
                        font-size: 8px;
                        font-weight: 500;
                        @include down($tablet) {
                            font-size: 14px;
                        }
                    }

                    .or-text {
                        font-size: 7px;
                        @include down($tablet) {
                            font-size: 12px;
                        }
                    }
                }

                .reward-list-item-or-card-type {
                    min-height: 32px;
                }

                .winner-selector-reward-list-item {
                    min-height: 21.5px;
                }

                .winner-selector {
                    padding: 2px 4px;
                    cursor: pointer;
                    min-width: 94px;
                    font-size: 12px;
                    @include down($tablet) {
                        font-size: 16px;
                        min-width: 160px;
                    }
                }
            }
        }
    }
}
