&.resources {
    .container {
        .heading {
            margin-bottom: 32px;
            @include down($mobile) {
                padding-left: 0;
            }
        }
    }
}
