.specifications {
    margin-bottom: 80px;

    .specs {
        .spec {
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            &:last-of-type {
                margin-bottom: 0;
            }

            &.game-content {
                align-items: flex-start;

                ul {
                    padding-left: 12px;

                    li {
                        margin-bottom: 4px;
                        color: $gray;
                    }
                }
            }

            &.rules {
                @include down($mobile) {
                    align-items: flex-start;
                }
            }

            .spec-logo {
                width: 20px;
                height: 20px;
                margin-right: 12px;
            }

            h6 {
                font-weight: 300;
                font-size: 16px;
                color: $gray;
            }
        }
    }
}

.rules-container-of-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 180px;
    @include down($mobile) {
        flex-direction: column;
        align-items: flex-start;
    }

    .rules-container {
        display: flex;
        align-items: center;
        @include down($mobile) {
            &:first-of-type {
                margin-bottom: 12px;
            }
        }
    }
}
