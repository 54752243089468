&.dashboard {
    .cover-photo {
        max-width: 100%;
        min-width: 100%;
        height: 350px;
        @include down($mobile-xl) {
            height: 240px;
        }
        @include down($extra-small) {
            height: 175px;
        }
    }

    .mission-container {
        margin-bottom: 56px;
        @include down($tablet) {
            margin-bottom: 32px;
        }

        p {
            line-height: 1.5;
            letter-spacing: 0.75px;
            font-size: 16px;
            @include down($tablet) {
                text-align: center;
                font-size: 14px;
            }
            @include down($mobile) {
                font-size: 12px;
            }
        }
    }

    .dashboard-container {
        min-height: calc(100vh - 300px);

        .welcome-message-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 80px;
        }

        .latest-releases, .popular-resources {
            display: flex;
            flex-wrap: wrap;
            @include down($large-tablet) {
                justify-content: space-between;
            }

            @include down($mobile-xl) {
                justify-content: center;
            }
        }

        .latest-releases {
            margin-bottom: 40px;
        }
    }
}
