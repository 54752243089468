.music-staff {
    height: 100px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    margin: 24px auto 100px;
    @media (max-width: $tablet) {
        margin: 24px auto 90px;
    }

    &.bass-staff {
        margin-bottom: 75px;
    }
    &.treble-staff {
        .treble-clef {
            height: 130px;
            left: -35px;
            position: absolute;
            @include down($mobile) {
                height: 120px;
            }
        }
        .note {
            position: absolute;
            &.middle-c {
                left: 100px;
                bottom: -32px;
                &::after {
                    content: "";
                    height: 2px;
                    width: 40px;
                    position: absolute;
                    top: 50%;
                    transform: translate(-25%, -50%);
                    background: $black;
                }
                &.selected::after {
                    background: $blue;
                }
                @media (max-width: $mobile-xl) {
                    left: 60px;
                    bottom: -27px;
                    &::after {
                        content: "";
                        width: 30px;
                    }
                }
                @media (max-width: $mobile) {
                    left: 60px;
                    bottom: -25px;
                    &::after {
                        content: "";
                        width: 20px;
                    }
                }
            }
            &.low-d {
                left: 140px;
                bottom: -22px;
                @media (max-width: $mobile-xl) {
                    left: 94px;
                    bottom: -18px;
                }
                @media (max-width: $mobile) {
                    left: 82px;
                    bottom: -16px;
                }
            }
            &.e {
                left: 180px;
                bottom: -11px;
                @media (max-width: $mobile-xl) {
                    left: 128px;
                    bottom: -8px;
                }
                @media (max-width: $mobile) {
                    left: 103px;
                    bottom: -6px;
                }
            }
            &.f {
                left: 220px;
                bottom: 1px;
                @media (max-width: $mobile-xl) {
                    left: 162px;
                    bottom: 3px;
                }
                @media (max-width: $mobile) {
                    left: 124px;
                    bottom: 6px;
                }
            }
            &.g {
                left: 260px;
                bottom: 13px;
                @media (max-width: $mobile-xl) {
                    left: 196px;
                    bottom: 16px;
                }
                @media (max-width: $mobile) {
                    left: 145px;
                    bottom: 18px;
                }
            }
            &.a {
                left: 300px;
                bottom: 25px;
                @media (max-width: $mobile-xl) {
                    left: 230px;
                    bottom: 28px;
                }
                @media (max-width: $mobile) {
                    left: 166px;
                    bottom: 31px;
                }
            }
            &.b {
                left: 340px;
                bottom: 38px;
                @media (max-width: $mobile-xl) {
                    left: 264px;
                    bottom: 40px;
                }
                @media (max-width: $mobile) {
                    left: 187px;
                    bottom: 43px;
                }
            }
            &.c {
                left: 380px;
                bottom: 50px;
                @media (max-width: $mobile-xl) {
                    left: 298px;
                    bottom: 52px;
                }
                @media (max-width: $mobile) {
                    left: 208px;
                    bottom: 55px;
                }
            }
            &.d {
                left: 420px;
                bottom: 62px;
                @media (max-width: $mobile-xl) {
                    left: 332px;
                    bottom: 64px;
                }
                @media (max-width: $mobile) {
                    left: 229px;
                    bottom: 67px;
                }
            }
            &.high-e {
                left: 460px;
                bottom: 74px;
                @media (max-width: $mobile-xl) {
                    left: 366px;
                    bottom: 77px;
                }
                @media (max-width: $mobile) {
                    left: 250px;
                    bottom: 79px;
                }
            }
            &.high-f {
                left: 500px;
                bottom: 87px;
                @media (max-width: $mobile-xl) {
                    left: 400px;
                    bottom: 89px;
                }
                @media (max-width: $mobile) {
                    left: 271px;
                    bottom: 92px;
                }
            }
            &.high-g {
                left: 538px;
                bottom: 100px;
                @media (max-width: $mobile-xl) {
                    left: 434px;
                    bottom: 102px;
                }
                @media (max-width: $mobile) {
                    left: 290px;
                    bottom: 104px;
                }
            }
        }
        .pitch-note {
            &.middle-c {
                left: 105px;
                @media (max-width: $mobile-xl) {
                    left: 63px;
                }
                @media (max-width: $mobile) {
                    left: 62px;
                }
            }
            &.low-d {
                left: 146px;
                @media (max-width: $mobile-xl) {
                    left: 98px;
                }
                @media (max-width: $mobile) {
                    left: 84px;
                }
            }
            &.e {
                left: 185px;
                @media (max-width: $mobile-xl) {
                    left: 133px;
                }
                @media (max-width: $mobile) {
                    left: 106px;
                }
            }
            &.f {
                left: 226px;
                @media (max-width: $mobile-xl) {
                    left: 168px;
                }
                @media (max-width: $mobile) {
                    left: 127px;
                }
            }
            &.g {
                left: 264px;
                @media (max-width: $mobile-xl) {
                    left: 201px;
                }
                @media (max-width: $mobile) {
                    left: 146px;
                }
            }
            &.a {
                left: 305px;
                @media (max-width: $mobile-xl) {
                    left: 236px;
                }
                @media (max-width: $mobile) {
                    left: 168px;
                }
            }
            &.b {
                left: 346px;
                @media (max-width: $mobile-xl) {
                    left: 270px;
                }
                @media (max-width: $mobile) {
                    left: 189px;
                }
            }
            &.c {
                left: 385px;
                @media (max-width: $mobile-xl) {
                    left: 303px;
                }
                @media (max-width: $mobile) {
                    left: 210px;
                }
            }
            &.d {
                left: 428px;
                @media (max-width: $mobile-xl) {
                    left: 336px;
                }
                @media (max-width: $mobile) {
                    left: 231px;
                }
            }
            &.high-e {
                left: 467px;
                @media (max-width: $mobile-xl) {
                    left: 372px;
                }
                @media (max-width: $mobile) {
                    left: 253px;
                }
            }
            &.high-f {
                left: 507px;
                @media (max-width: $mobile-xl) {
                    left: 405px;
                }
                @media (max-width: $mobile) {
                    left: 273px;
                }
            }
            &.high-g {
                left: 540px;
                @media (max-width: $mobile-xl) {
                    left: 435px;
                }
                @media (max-width: $mobile) {
                    left: 290px;
                }
            }
        }
    }
    &.bass-staff {
        .bass-clef {
            height: 70px;
            top: 3px;
            position: absolute;
            @include down($mobile) {
                height: 60px;
            }
        }
        .note {
            position: absolute;
            &.low-g {
                left: 100px;
                bottom: -11px;
                @media (max-width: $mobile-xl) {
                    left: 60px;
                    bottom: -8px;
                }
                @media (max-width: $mobile) {
                    left: 60px;
                    bottom: -6px;
                }
            }
            &.low-a {
                left: 140px;
                bottom: 1px;
                @media (max-width: $mobile-xl) {
                    left: 94px;
                    bottom: 3px;
                }
                @media (max-width: $mobile) {
                    left: 82px;
                    bottom: 6px;
                }
            }
            &.low-b {
                left: 180px;
                bottom: 13px;
                @media (max-width: $mobile-xl) {
                    left: 128px;
                    bottom: 16px;
                }
                @media (max-width: $mobile) {
                    left: 103px;
                    bottom: 18px;
                }
            }
            &.bass-c {
                left: 220px;
                bottom: 25px;
                @media (max-width: $mobile-xl) {
                    left: 162px;
                    bottom: 28px;
                }
                @media (max-width: $mobile) {
                    left: 124px;
                    bottom: 31px;
                }
            }
            &.bass-d {
                left: 260px;
                bottom: 38px;
                @media (max-width: $mobile-xl) {
                    left: 196px;
                    bottom: 40px;
                }
                @media (max-width: $mobile) {
                    left: 145px;
                    bottom: 43px;
                }
            }
            &.bass-e {
                left: 300px;
                bottom: 50px;
                @media (max-width: $mobile-xl) {
                    left: 230px;
                    bottom: 52px;
                }
                @media (max-width: $mobile) {
                    left: 166px;
                    bottom: 55px;
                }
            }
            &.bass-f {
                left: 340px;
                bottom: 62px;
                @media (max-width: $mobile-xl) {
                    left: 264px;
                    bottom: 64px;
                }
                @media (max-width: $mobile) {
                    left: 187px;
                    bottom: 67px;
                }
            }
            &.bass-g {
                left: 380px;
                bottom: 74px;
                @media (max-width: $mobile-xl) {
                    left: 298px;
                    bottom: 77px;
                }
                @media (max-width: $mobile) {
                    left: 208px;
                    bottom: 79px;
                }
            }
            &.bass-a {
                left: 420px;
                bottom: 87px;
                @media (max-width: $mobile-xl) {
                    left: 332px;
                    bottom: 89px;
                }
                @media (max-width: $mobile) {
                    left: 229px;
                    bottom: 92px;
                }
            }
            &.bass-b {
                left: 460px;
                bottom: 99px;
                @media (max-width: $mobile-xl) {
                    left: 366px;
                    bottom: 101px;
                }
                @media (max-width: $mobile) {
                    left: 250px;
                    bottom: 105px;
                }
            }
            &.bass-middle-c {
                left: 500px;
                bottom: 111px;
                &::after {
                    content: "";
                    height: 2px;
                    width: 40px;
                    position: absolute;
                    top: 50%;
                    transform: translate(-25%, -50%);
                    background: black;
                }
                &.selected::after {
                    background: $blue;
                }
                @media (max-width: $mobile-xl) {
                    left: 400px;
                    bottom: 113px;
                    &::after {
                        content: "";
                        width: 30px;
                    }
                }
                @media (max-width: $mobile) {
                    left: 271px;
                    bottom: 118px;
                    &::after {
                        content: "";
                        width: 20px;
                    }
                }
            }
        }
        .pitch-note {
            position: absolute;
            bottom: -40px;
            @media (max-width: $mobile-xl) {
                bottom: -32px;
            }
            &.low-g {
                left: 105px;
                @media (max-width: $mobile-xl) {
                    left: 63px;
                }
                @media (max-width: $mobile) {
                    left: 62px;
                }
            }
            &.low-a {
                left: 146px;
                @media (max-width: $mobile-xl) {
                    left: 98px;
                }
                @media (max-width: $mobile) {
                    left: 84px;
                }
            }
            &.low-b {
                left: 185px;
                @media (max-width: $mobile-xl) {
                    left: 133px;
                }
                @media (max-width: $mobile) {
                    left: 106px;
                }
            }
            &.bass-c {
                left: 226px;
                @media (max-width: $mobile-xl) {
                    left: 168px;
                }
                @media (max-width: $mobile) {
                    left: 127px;
                }
            }
            &.bass-d {
                left: 264px;
                @media (max-width: $mobile-xl) {
                    left: 201px;
                }
                @media (max-width: $mobile) {
                    left: 146px;
                }
            }
            &.bass-e {
                left: 305px;
                @media (max-width: $mobile-xl) {
                    left: 236px;
                }
                @media (max-width: $mobile) {
                    left: 168px;
                }
            }
            &.bass-f {
                left: 346px;
                @media (max-width: $mobile-xl) {
                    left: 270px;
                }
                @media (max-width: $mobile) {
                    left: 189px;
                }
            }
            &.bass-g {
                left: 385px;
                @media (max-width: $mobile-xl) {
                    left: 303px;
                }
                @media (max-width: $mobile) {
                    left: 210px;
                }
            }
            &.bass-a {
                left: 428px;
                @media (max-width: $mobile-xl) {
                    left: 336px;
                }
                @media (max-width: $mobile) {
                    left: 231px;
                }
            }
            &.bass-b {
                left: 467px;
                @media (max-width: $mobile-xl) {
                    left: 372px;
                }
                @media (max-width: $mobile) {
                    left: 253px;
                }
            }
            &.bass-middle-c {
                left: 507px;
                @media (max-width: $mobile-xl) {
                    left: 405px;
                }
                @media (max-width: $mobile) {
                    left: 273px;
                }
            }
        }
    }
    .line-1,
    .line-2,
    .line-3,
    .line-4,
    .line-5 {
        background: black;
        height: 2px;
        width: 600px;
        @media (max-width: $tablet) {
            width: 550px;
        }
        @media (max-width: $mobile-xl) {
            width: 450px;
        }
        @media (max-width: $mobile) {
            width: 375px;
        }
        @media (max-width: $small-mobile) {
            width: 290px;
        }
    }
    .note {
        height: 24px;
        width: 24px;
        border-radius: 50%;
        border: 2.5px black solid;
        @media (max-width: $mobile-xl) {
            width: 20px;
            height: 20px;
        }
        @media (max-width: $mobile) {
            width: 15px;
            height: 15px;
        }
        &.selected {
            border: 2.5px $blue solid;
            visibility: visible;
            opacity: 1;
        }
        &.transparent {
            opacity: 0.25;
            visibility: hidden;
        }
        &.translucent {
            opacity: 0.4;
        }
    }
    .pitch-note {
        position: absolute;
        bottom: -60px;
        background: transparent;
        border: none;
        outline: none;
        font-size: 20px;
        @media (max-width: $mobile-xl) {
            bottom: -55px;
        }
        @media (max-width: $mobile) {
            bottom: -50px;
            font-size: 16px;
        }
        &.selected {
            color: $blue;
            visibility: visible;
            opacity: 1;
        }
        &.transparent {
            opacity: 0.25;
            visibility: hidden;
        }
    }
}

.music-button-container,
.pitch-buttons-container {
    margin: 0 auto 40px;
    width: 300px;
    display: flex;
    justify-content: space-between;
    @include down($mobile) {
        width: 275px;
    }
}

.pitch-text {
    margin-top: 24px;
}

.chromatic-scale-container {
    display: flex;
    align-items: center;
    justify-content: unset;
    flex-direction: row;
    width: unset;
    margin-top: 24px;

    span {
        display: inline-block;
        font-size: 20px;
        margin-right: 12px;
        @include down($tablet) {
            font-size: 16px;
        }
        @include down($mobile) {
            font-size: 14px;
            margin-right: 8px;
        }
        @include down($extra-small) {
            font-size: 12px;
        }
    }

    .red {
        color: $red;
    }
}

.or-statement {
    margin: 24px auto 0;
}

.key-signature {
    position: absolute;
    font-size: 20px;
    @media (max-width: $mobile-xl) {
        font-size: 16px;
    }
    &.key-signature-c {
        left: 75px;
        top: 24px;
        @media (max-width: $mobile-xl) {
            left: 55px;
            top: 27px;
        }
    }
    &.key-signature-f {
        top: -13px;
        left: 85px;
        @media (max-width: $mobile-xl) {
            left: 65px;
            top: -10px;
        }
    }
}

.pitch {
    font-size: 20px;
    @media (max-width: $mobile-xl) {
        font-size: 16px;
    }
    &.natural-pitch {
        position: absolute;
        left: 360px;
        bottom: 48px;
        @media (max-width: $mobile-xl) {
            left: 280px;
            bottom: 51px;
        }
        @media (max-width: $mobile) {
            left: 193px;
            bottom: 52px;
        }
    }
}
.measure-bar {
    position: absolute;
    background: $black;
    height: 100%; 
    width: 3px;
    right: 100px;
    @media (max-width: $mobile) {
        right: 50px;
    }
}

.rhythm-example-container {
    display: flex;
    margin: 24px auto 40px;

    .rhythm-box {
        width: 55px;
        height: 35px;
        border: 1px solid $black;
        margin-right: 25px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:last-of-type {
            margin-right: 0;
        }
        &.first {
            animation: rhythm 4s 0s infinite ease-in-out;
            &.faster {
                animation: rhythm 2s 0s infinite ease-in-out;
            }
        }
        &.second {
            animation: rhythm 4s 1s infinite ease-in-out;
            &.faster {
                animation: rhythm 2s 0.5s infinite ease-in-out;
            }
        }
        &.third {
            animation: rhythm 4s 2s infinite ease-in-out;
            &.faster {
                animation: rhythm 2s 1s infinite ease-in-out;
            }
        }
        &.fourth {
            animation: rhythm 4s 3s infinite ease-in-out;
            &.faster {
                animation: rhythm 2s 1.5s infinite ease-in-out;
            }
        }
    }
}

@keyframes rhythm {
    0% {
        background: none;
    }
    25% {
        background: $green;
    }
    35% {
        background: $white;
    }
    50% {
        background: none;
    }
}

.btn-container {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 40px;
}

.time-signature {
    font-size: 50px;
    position: absolute;
    &.top-signature {
        left: 65px;
        top: -7px;
        @media (max-width: $mobile-xl) {
            top: -7px;
        }
    }
    &.bottom-signature {
        left: 65px;
        top: 45px;
        @media (max-width: $mobile-xl) {
            top: 45px;
        }
    }
}

.types-of-symbol-container {
    margin: 24px auto 0;
    .symbol-container {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
    }
    .symbol-name {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: montserrat;
        min-width: 145.15px;
        font-size: 12px;
        .symbol-type {
            margin-bottom: 5px;
        }
    }
    .whole-note {
        width: 9px;
        height: 7px;
        border-radius: 50%;
        border: 1px $black solid;
    }
    .whole-rest,
    .half-rest {
        height: 3px;
        width: 8px;
        background: $black;
    }
    .quarter-rest,
    .eighth-rest {
        img {
            height: 10px;
            width: 10px;
        }
    }
}

.half-note {
    width: 5px;
    height: 4px;
    border-radius: 50%;
    border: 1px $black solid;
    position: relative;
    &:after {
        content: "";
        position: absolute;
        height: 15px;
        width: 1px;
        background: $black;
        right: 0;
        bottom: 0;
        transform: translate(100%, 0);
    }
}

.dot-container {
    margin-bottom: 25px;
}

.dot {
    width: 2.5px;
    height: 2.5px;
    border-radius: 100%;
    border: solid 1px $black;
    background: $black;
    margin-left: 2px;
}

.augmentation-container {
    display: flex;
    margin: 64px auto 0;
    .half-note {
        width: 32px;
        height: 32px;
        &:after {
            content: "";
            height: 60px;
            bottom: 15px;
        }
    }
    .dot {
        width: 6px;
        height: 6px;
        margin-left: 4px;
    }
}

.tie {
    margin-bottom: 25px;
    margin: 32px auto 0;
    width: 80px;
    height: 80px;
}

.dynamic-notation-container {
    margin: 40px auto 0;
}

.dynamic-labels {
    width: 280px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
}

.dynamic-ranking-line {
    margin-bottom: 15px;
    width: 280px;
    height: 2px;
    background: $black;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        height: 20px;
        width: 2px;
        background: $black;
        transform: translate(0, -50%);
    }
    &:after {
        content: "";
        position: absolute;
        height: 20px;
        width: 2px;
        background: $black;
        transform: translate(0, -50%);
        right: 0;
    }
    .dynamic-line {
        height: 20px;
        width: 2px;
        background: $black;
        transform: translate(0, -50%);
        position: absolute;
        &.line-pp {
            left: 40px;
        }
        &.line-p {
            left: 80px;
        }
        &.line-mp {
            left: 120px;
        }
        &.line-mf {
            left: 160px;
        }
        &.line-f {
            left: 200px;
        }
        &.line-ff {
            left: 240px;
        }
    }
}

.dynamic-names {
    margin-bottom: 50px;
    width: 280px;
    display: flex;
    justify-content: space-between;

    h4 {
        width: 40px;
        min-width: 40px;
        max-width: 40px;
        transform: translate(-50%, 0);
        font-size: 12px;
        text-align: center;
    }
}

.dynamic-holder {
    width: 300px;
    display: flex;
    justify-content: space-between;

    .dynamic-container {
        margin-bottom: 75px;
        width: 120px;
        height: 0;
        position: relative;
        .crescendo {
            &:before {
                content: "";
                position: absolute;
                background: $black;
                height: 1px;
                width: 120px;
                transform: rotate(-2deg);
            }
            &:after {
                content: "";
                position: absolute;
                background: $black;
                height: 1px;
                width: 120px;
                transform: rotate(2deg);
                bottom: -6px;
            }
        }
        .diminuendo {
            &:before {
                content: "";
                position: absolute;
                background: $black;
                height: 1px;
                width: 120px;
                transform: rotate(2deg);
            }
            &:after {
                content: "";
                position: absolute;
                background: $black;
                height: 1px;
                width: 120px;
                transform: rotate(-2deg);
                bottom: -6px;
            }
        }
        .dynamic-name {
            top: 15px;
            left: 50%;
            transform: translate(-50%, 0);
            position: absolute;
        }
    }
}
