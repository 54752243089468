.legend-container {
    display: flex;
    flex-direction: column;
    margin: 40px auto 56px;
    max-width: 600px;
    @include down($tablet) {
        margin-bottom: 40px;
        max-width: 300px;
    }

    .legend-item {
        margin-bottom: 12px;
    }
}

.slider-puzzle-builder-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    @import "./slider-puzzle-global";

    .create-puzzle-container {
        margin-bottom: 40px;
        @include down($tablet) {
            margin-bottom: 0;
        }

        .puzzle-row {
            display: flex;

            &.desktop {
                @include down($tablet) {
                    display: none;
                }
            }

            &.mobile {
                @include up($tablet) {
                    display: none;
                }
            }

            .select-dropdown {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                width: 75px;
                cursor: pointer;

                @include down($tablet) {
                    height: 32px;
                    width: 50px;
                }
                @include down($mobile) {
                    width: 40px;
                    font-size: 12px;
                }
                @include down($small-mobile) {
                    width: 36px;
                    font-size: 10px;
                }
            }
        }
    }

    .preview-buttons-container {
        display: flex;
        justify-content: space-around;
        width: 400px;
        margin-bottom: 40px;
        @include down($mobile-xl) {
            width: 300px;
        }
    }

    .form-container {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        width: 400px;
        @include down($mobile) {
            width: 300px;
        }
        @include down($small-mobile) {
            width: 100%;
        }

        .form-field {
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;
            &:last-of-type {
                margin-bottom: 32px;
            }

            span {
                margin-bottom: 8px;
                font-size: 20px;
                @include down($tablet) {
                    margin-bottom: 4px;
                    font-size: 16px;
                }
            }

            input {
                padding: 12px;
                border: none;
                border-bottom: 1px solid $black;
                outline: none;
                font-size: 18px;
                cursor: pointer;

                &:hover {
                    border-bottom: 2px solid $black;
                }

                &.validated {
                    border-bottom: 1px solid $green;
                }
            }

            input {
                @include down($tablet) {
                    padding: 8px;
                }
                &.invalid {
                    border-bottom: 1px solid $red;
                }
            }
        }
    }
}
