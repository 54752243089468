$CARRIER: polygon(0% 0%, 0% 100%, 80% 100%, 85% 88%, 90% 85%, 100% 80%, 100% 20%, 90% 15%, 85% 12%, 80% 0%, 0% 0%);

.carrier .ship,
#carrier-copy {
    clip-path: $CARRIER;
    width: 250px;
    @include down($small-desktop) {
        width: 225px;
    }
    @include down($large-tablet) {
        width: 200px;
    }
    @include down($tablet - 1) {
        width: 250px;
    }
    @include down($mobile-xl) {
        width: 225px;
    }
    @include down($mobile) {
        width: 200px;
    }
    @include down(450px) {
        width: 187.5px;
    }
    @include down($small-mobile) {
        width: 175px;
    }
    @include down(375px) {
        width: 160px;
    }
    @include down($extra-small) {
        width: 142.5px;
    }
}

// fleet board
.carrier {
    height: 70px;
    &.vertical {
        height: 270px;
        .ship {
            transform: rotate(90deg) translate(105px, -2px);
        }
    }
    @include down($small-desktop) {
        height: 65px;
        .ship {
            transform: translateX(18px);
        }
        &.vertical {
            height: 250px;
            .ship {
                transform: rotate(90deg) translate(95px, -15px);
            }
        }
    }
    @include down($large-tablet) {
        height: 60px;
        .ship {
            transform: translateX(30px);
        }
        &.vertical {
            height: 220px;
            .ship {
                transform: rotate(90deg) translate(85px, -30px);
            }
        }
    }
    @include down($tablet - 1) {
        height: 70px;
        .ship {
            transform: unset;
        }
        &.vertical {
            height: 270px;
            .ship {
                transform: rotate(90deg) translate(105px, -5px);
            }
        }
    }
    @include down($mobile-xl) {
        height: 65px;
        .ship {
            transform: translateX(15px);
        }
        &.vertical {
            height: 240px;
            .ship {
                transform: rotate(90deg) translate(90px, -12px);
            }
        }
    }
    @include down($mobile) {
        height: 60px;
        .ship {
            transform: translateX(30px);
        }
        &.vertical {
            height: 215px;
            .ship {
                transform: rotate(90deg) translate(80px, -25px);
            }
        }
    }
    @include down(450px) {
        height: 57.5px;
        &.vertical {
            height: 205px;
            .ship {
                transform: rotate(90deg) translate(75px, -35px);
            }
        }
    }
    @include down($small-mobile) {
        height: 55px;
        .ship {
            transform: translateX(40px);
        }
        &.vertical {
            height: 195px;
            .ship {
                transform: rotate(90deg) translate(75px, -40px);
            }
        }
    }
    @include down(375px) {
        height: 52px;
        .ship {
            transform: translateX(45px);
        }
        &.vertical {
            height: 180px;
            .ship {
                transform: rotate(90deg) translate(70px, -45px);
            }
        }
    }
    @include down($extra-small) {
        height: 48.5px;
        .ship {
            transform: translateX(50px);
        }
        &.vertical {
            height: 155px;
            .ship {
                transform: rotate(90deg) translate(60px, -60px);
            }
        }
    }
}

// game board
#carrier-copy {
    &.vertical {
        transform: rotate(90deg) translate(40%, 200%);
    }
}