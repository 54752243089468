.rebus-board {
    margin-top: 64px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    @include down($desktop) {
        flex-direction: column;
        align-items: center;
    }

    .puzzle {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include down($desktop) {
            margin-bottom: 48px;
        }
        @include down($mobile) {
            margin-bottom: 40px;
        }

        .outer-container {
            margin-bottom: 40px;
        }

        .rebus-img {
            height: 250px;
            width: 250px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 40px;
        }

        .rebus-textarea {
            min-width: 100%;
            max-width: 100%;
            min-height: 85px;
            max-height: 85px;
            border-radius: 4px;
            padding: 16px;
            font-size: 20px;
            margin-bottom: 40px;
            @include down($mobile) {
                font-size: 16px;
            }
        }
    }

    .rebus-tabs-container {
        width: 450px;
        @include down($mobile) {
            width: calc(100vw - 32px);
        }

        .tabs-container {
            width: 100%;

            .tab {
                span {
                    font-size: 20px;
                    @include down($mobile) {
                        font-size: 16px;
                    }
                    @include down($small-mobile) {
                        font-size: 14px;
                    }
                }
            }
        }

        .random-container {
            margin: 40px 0 0 24px;
        }

        .select-puzzle-container {
            margin-top: 40px;
            @include down($mobile) {
                margin: 40px 12px 0;
            }

            .select-puzzle-header {
                margin-left: 40px;
                margin-bottom: 16px;
                font-size: 20px;
                @include down($desktop) {
                    margin: 0 24px 12px;
                }
                @include down($mobile) {
                    margin: 0 0 12px;
                }
            }

            .select-puzzle-labels {
                display: flex;
                flex-wrap: wrap;
                margin: 0 40px;
                @include down($desktop) {
                    margin: 0 24px;
                }
                @include down($mobile) {
                    margin: 0;
                }

                .label-container {
                    margin-bottom: 12px;
                    padding: 4px;
                    display: flex;
                    align-items: center;
                    width: 65px;
                    cursor: pointer;
                    @include down($desktop) {
                        width: 60px;
                        margin-bottom: 8px;
                    }

                    input {
                        margin-right: 8px;
                    }
                }
            }
        }
    }
}
