.position-number-tag {
    font-size: 10px;
    border-radius: 4px;
    padding: 2px 4px;
    display: inline-block;
    margin-right: 4px;
    border-width: 1px;
    border-style: solid;

    &.first {
        background: $green;
        color: $white;
    }
    &.second {
        background: $skyblue;
        color: $white;
    }
    &.third {
        background: $orange;
        color: $white;
    }
    &.fourth {
        background: $purple;
        color: $white;
    }
    &.fifth {
        background: $pink;
        color: $white;
    }
    &.sixth {
        background: $yellow;
        color: $black;
    }
    &.seventh {
        background: $acorn;
        color: $white;
    }
    &.eighth {
        background: $red;
        color: $white;
    }
    &.ninth {
        background: $gray;
        color: $white;
    }
    &.tenth {
        background: $blue;
        color: $white;
    }
    &.eleventh {
        background: $black;
        color: $white;
    }
    &.twelfth {
        background: $lightorange;
        color: $black;
    }
    &.thirteenth {
        background: $lavender;
        color: $black;
    }
    &.fourteenth {
        background: $lightblue;
        color: $black;
    }
    &.fifteenth {
        background: $lightyellow;
        color: $black;
    }
    &.sixteenth {
        background: $salmon;
        color: $black;
    }
    &.seventeenth {
        background: $sunshine;
        color: $black;
    }
    &.eighteenth {
        background: $slate;
        color: $white;
    }
    &.nineteenth {
        background: $beige;
        color: $black;
    }
    &.twentieth {
        background: $white;
        color: $black;
    }
}
