.loading-container {
	height: 100px;
	width: calc(100% - 64px);
	font-family: Helvetica;
	position: fixed;
	top: 300px;
	z-index: 2;
	left: 50%;
	transform: translateX(-50%);
	@include down($mobile) {
	}

	.loader {
		height: 20px;
		width: 250px;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
	}

	.dot {
		animation-name: loader;
		animation-timing-function: ease-in-out;
		animation-duration: 1.5s;
		animation-iteration-count: infinite;
		height: 15px;
		width: 15px;
		border-radius: 100%;
		background-color: $black;
		position: absolute;
		border: 2px solid $white;
		&:first-child {
			background-color: $green;
			animation-delay: 0.5s;
			left: 100%;
		}
		&:nth-child(2) {
			background-color: $purple;
			animation-delay: 0.4s;
			left: 80%;
		}
		&:nth-child(3) {
			background-color: $pink;
			animation-delay: 0.3s;
			left: 60%;
		}
		&:nth-child(4) {
			background-color: $orange;
			animation-delay: 0.2s;
			left: 40%;
		}
		&:nth-child(5) {
			background-color: $skyblue;
			animation-delay: 0.1s;
			left: 20%;
		}
		&:nth-child(6) {
			background-color: $yellow;
			animation-delay: 0s;
			left: 0;
		}
	}

	.text {
		position: absolute;
		top: 200%;
		left: 0;
		right: 0;
		width: 4rem;
		margin: auto;

		&:after {
			content: "Loading...";
			font-weight: bold;
		}
	}
}

@keyframes loader {
	15% {
		transform: scale(1);
	}
	45% {
		transform: scale(2.5);
	}
	63% {
		transform: scale(2.5);
	}
	95% {
		transform: scale(1);
	}
}
