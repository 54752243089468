.keyboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;

    .keyboard-row {
        display: flex;

        .computer-key {
            padding: 2px 4px;
            margin: 2px;
            border: 1px solid $black;
            border-radius: 4px;
            min-width: 32px;
            min-height: 35px;
            text-transform: uppercase;
            outline: none;
            background: $beige;
            cursor: pointer;
            font-size: 16px;
            @include down($mobile) {
                min-width: 30px;
                min-height: 30px;
                font-size: 14px;
            }
            @include down($extra-small) {
                min-width: 26px;
                min-height: 26px;
                font-size: 10px;
            }

            &:disabled {
                cursor: unset;
            }

            &:focus {
                background: $beige;
            }

            &.included {
                background: $green;
            }

            &.excluded {
                background: $slate;
            }

            .backspace-img {
                max-width: 100%;
                max-height: 35px;
                @include down($mobile) {
                    max-height: 28px;
                }
            }
        }
    }
}