$BATTLESHIP: polygon(
    0% 50%,
    5% 60%,
    10% 70%,
    15% 80%,
    20% 90%,
    21% 91%,
    22% 92%,
    23% 93%,
    24% 94%,
    25% 95%,
    26% 96%,
    27% 97%,
    28% 98%,
    29% 99%,
    30% 100%,
    70% 100%,
    71% 99%,
    72% 98%,
    73% 97%,
    74% 96%,
    75% 95%,
    76% 94%,
    77% 93%,
    78% 92%,
    79% 91%,
    80% 90%,
    85% 80%,
    90% 70%,
    91% 68%,
    92% 66%,
    93% 64%,
    94% 62%,
    95% 60%,
    100% 50%,
    95% 40%,
    94% 38%,
    93% 36%,
    92% 34%,
    91% 32%,
    90% 30%,
    85% 20%,
    80% 10%,
    79% 9%,
    78% 8%,
    77% 7%,
    76% 6%,
    75% 5%,
    74% 4%,
    73% 3%,
    72% 2%,
    71% 1%,
    70% 0%,
    30% 0%,
    29% 1%,
    28% 2%,
    27% 3%,
    26% 4%,
    25% 5%,
    24% 6%,
    23% 7%,
    22% 8%,
    21% 9%,
    20% 10%,
    15% 20%,
    10% 30%,
    5% 40%,
    0% 50%
);

.battleship .ship,
#battleship-copy {
    clip-path: $BATTLESHIP;
    width: 200px;
    @include down($small-desktop) {
        width: 180px;
    }
    @include down($large-tablet) {
        width: 160px;
    }
    @include down($tablet - 1) {
        width: 200px;
    }
    @include down($mobile-xl) {
        width: 180px;
    }
    @include down($mobile) {
        width: 160px;
    }
    @include down(450px) {
        width: 150px;
    }
    @include down($small-mobile) {
        width: 140px;
    }
    @include down(375px) {
        width: 128px;
    }
    @include down($extra-small) {
        width: 114px;
    }
}

// fleet board
.battleship {
    height: 70px;
    .ship {
        transform: translateX(25px);
    }
    &.vertical {
        height: 220px;
        margin-bottom: 24px;
        .ship {
            transform: rotate(90deg) translate(75px, -27px);
        }
    }
    @include down($small-desktop) {
        height: 65px;
        .ship {
            transform: translateX(40px);
        }
        &.vertical {
            height: 195px;
            .ship {
                transform: rotate(90deg) translate(70px, -37px);
            }
        }
    }
    @include down($large-tablet) {
        height: 60px;
        .ship {
            transform: translateX(50px);
        }
        &.vertical {
            height: 175px;
            .ship {
                transform: rotate(90deg) translate(60px, -50px);
            }
        }
    }
    @include down($tablet - 1) {
        height: 70px;
        .ship {
            transform: translateX(25px);
        }
        &.vertical {
            height: 215px;
            .ship {
                transform: rotate(90deg) translate(75px, -30px);
            }
        }
    }
    @include down($mobile-xl) {
        height: 65px;
        .ship {
            transform: translateX(40px);
        }
        &.vertical {
            height: 200px;
            .ship {
                transform: rotate(90deg) translate(70px, -37px);
            }
        }
    }
    @include down($mobile) {
        height: 60px;
        .ship {
            transform: translateX(50px);
        }
        &.vertical {
            height: 175px;
            .ship {
                transform: rotate(90deg) translate(60px, -45px);
            }
        }
    }
    @include down(450px) {
        height: 57.5px;
        &.vertical {
            height: 165px;
            .ship {
                transform: rotate(90deg) translate(55px, -55px);
            }
        }
    }
    @include down($small-mobile) {
        height: 55px;
        .ship {
            transform: translateX(60px);
        }
        &.vertical {
            height: 150px;
            .ship {
                transform: rotate(90deg) translate(50px, -60px);
            }
        }
    }
    @include down(375px) {
        height: 52px;
        .ship {
            transform: translateX(60px);
        }
        &.vertical {
            height: 140px;
            .ship {
                transform: rotate(90deg) translate(45px, -63px);
            }
        }
    }
    @include down($extra-small) {
        height: 48.5px;
        &.vertical {
            height: 125px;
            .ship {
                transform: rotate(90deg) translate(42px, -75px);
            }
        }
    }
}

// game board
#battleship-copy {
    &.vertical {
        transform: rotate(90deg) translate(37%, 150%);
    }
}
