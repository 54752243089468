.footer {
    width: 100%;
    padding: 48px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba($orange, 0.4);
    @include down($large-tablet) {
        padding: 40px 0;
    }
    @include down($mobile) {
        padding: 56px 0;
    }

    .contact-us-label {
        margin-bottom: 16px;
        font-size: 16px;
        letter-spacing: 1.25px;
        font-weight: 600;
        cursor: pointer;
    }
    
    .contact-icons {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 28px;
        @include down($mobile) {
            flex-direction: column;
            margin-bottom: 20px;
        }
    }
    
    .copy-button {
        @include down($mobile) {
            margin-bottom: 8px;
        }
    }
    
    .divider {
        font-weight: 400;
        font-size: 20px;
        margin-left: 10px;
        margin-right: 10px;
        @include down($mobile) {
            display: none;
        }
    }

    .budding-acorn {
        font-size: 16px;
    }
}
