.question-and-answer {
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;
    margin: 80px auto 0;
    max-width: 650px;
    width: calc(100vw - 32px);
    word-wrap: break-word;
    @media (max-width: $tablet) {
        width: calc(100vw - 40px);
        margin-top: 64px;
    }
    @include down($mobile) {
        margin-top: 40px;
    }

    .btn-container {
        width: 450px;
        @include down($mobile) {
            width: 320px;
        }
        @include down($extra-small) {
            width: 300px;
        }

        .default-btn {
            background: $purple;
            &:nth-of-type(2) {
                background: $green;
            }

            &:disabled {
                background-color: $disabled;
                color: $slate;
            }
        }
    }

    .content {
        font-size: 24px;
        text-align: center;
        line-height: 1.5;
        margin-bottom: 0;
        @include down($mobile) {
            font-size: 20px;
        }

        &:not(:last-of-type) {
            margin-bottom: 12px;
        }
    }

    .content-list {
        font-size: 24px;
        line-height: 1.5;

        .content-list-item {
            color: $slate;
            margin-bottom: 8px;
            @include down($mobile) {
                font-size: 20px;
            }
        }
    }

    .answer-container {
        margin-top: 40px;
        @include down($mobile) {
            margin-top: 24px;
        }
        
        p, li {
            color: $skyblue;
        }
    }
}
