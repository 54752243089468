.ship, .ship-copy {
    background: $darkslate;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    height: 50px;
    
    &.ship-copy {
        top: 0;
        left: 0;
        transform: translate(0, -1%);
        z-index: 1;
        &.hidden {
            display: none;
        }
    }

    @include down($small-desktop) {
        height: 45px;
    }
    @include down($large-tablet) {
        height: 40px;
    }
    @include down($tablet - 1) {
        height: 50px;
    }
    @include down($mobile-xl) {
        height: 45px;
    }
    @include down($mobile) {
        height: 40px;
    }
    @include down(450px) {
        height: 37.5px;
    }
    @include down($small-mobile) {
        height: 35px;
    }
    @include down(375px) {
        height: 32px;
    }
    @include down($extra-small) {
        height: 28.5px;
    }

    .peg {
        border-radius: 50%;
        width: 8px;
        height: 8px;
        border: $black 1px solid;
        z-index: 1;
        @include down($mobile) {
            width: 6px;
            height: 6px;
        }
        &.hit {
            background: $red;
        }
    }
}