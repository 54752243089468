.vitamin {
    margin-bottom: 16px;
    list-style: none;

    .vitamin-name {
        display: inline-block;
        margin-bottom: 4px;
        color: $skyblue;
        font-weight: 600;
        letter-spacing: 1.25px;
        text-decoration: underline;
        text-underline-offset: 2px;
    }

    .vitamin-label {
        display: inline-block;
        margin-right: 6px;
        font-weight: 600;
    }

    .vitamin-purposes {
        margin-bottom: 8px;
    }
}