.subscribe-container {
    margin-bottom: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .subscribe-label {
        margin-bottom: 16px;
        font-size: 16px;
        letter-spacing: 1.25px;
        font-weight: 600;
    }
}

.subscribe-form {
    height: 36px;
    width: 250px;
    border-radius: 4px 0 0 4px;
    display: flex;

    .subscribe-input {
        height: 100%;
        border: none;
        width: calc(100% - 32px);
        padding: 8px;
        outline: none;
        background-color: rgba($orange, 0.25);
        color: $black;
        letter-spacing: 1.5px;
        border-radius: 4px 0 0 4px;

        &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $slate;
            opacity: 1;
            /* Firefox */
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            /* Microsoft Edge */
            color: $slate;
        }
    }

    .subscribe {
        width: 32px;
        height: 100%;
        background: $orange;
        outline: none;
        border: none;
        border-radius: 0 4px 4px 0;

        img {
            width: 100%;
            height: 100%;
            padding: 8px;
        }
    }
}
