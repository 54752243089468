$frosted-gray: #e2e3e8;
$frosted-white: #f6f6fb;

.checkerboard {
    display: flex;
    flex-direction: column;
    width: 500px;
    height: 500px;
    @include down($mobile-xl) {
        width: 450px;
        height: 450px;
    }
    @include down($mobile) {
        width: 375px;
        height: 375px;
    }
    @include down($small-mobile) {
        width: 290px;
        height: 290px;
    }

    &.inverted {
        transform: rotate(180deg);
    }

    .row {
        display: flex;
        width: 100%;
        height: 100%;

        .square {
            width: 12.5%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2rem;
            border: 1px solid $frosted-gray;

            &.white {
                background-color: $frosted-white;
            }

            &.black {
                background-color: $frosted-gray;
            }
        }
    }
}