.top-page-container {
    position: relative;
    .direction-btn-container {
        position: absolute;
        right: 0;
        top: 0;
        width: 210px;
        transform: translate(-45%, -15%);
        @include down($mobile-xl) {
            width: unset;
            transform: translate(0, -25%);
        }

        .outer-container {
            @include down($mobile-xl) {
                display: none;
            }
        }
        .mobile-btn {
            border-radius: 50%;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            border: none;
            cursor: pointer;
            @include up($mobile-xl) {
                display: none;
            }
            @include down($extra-small) {
                width: 40px;
                height: 40px;
            }

            img {
                width: 100%;
                position: relative;
                &::after {
                    content: "hello";
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
    }
}

.bottom-direction-btn-container {
    position: absolute;
    right: 15px;
    top: 30px;
    transform: rotate(180deg);
    @include up($mobile-xl) {
        right: 56px;
    }

    .mobile-btn {
        border-radius: 50%;
        width: 50px;
        height: 50%;
        display: flex;
        align-items: center;
        border: none;
        cursor: pointer;
        @include down($extra-small) {
            width: 40px;
            height: 40px;
        }

        img {
            width: 100%;
            position: relative;
            &::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }
}
