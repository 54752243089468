#note-selector {
    font-size: 24px;
    padding: 4px;
    outline: none;
    margin: 64px auto 64px;
    display: flex;
    cursor: pointer;
}
.chord-diagram {
    width: 20%;
    margin-bottom: 35px;
    @media (max-width: $desktop) {
        width: 25%;
    }
    @media (max-width: $tablet) {
        width: 33.33%;
    }
    @media (max-width: $mobile-xl) {
        width: 50%;
    }
    @media (max-width: $mobile) {
        width: 100%;
    }
    .chord-name {
        margin-bottom: 8px;
    }
    .fret-board {
        height: 136px;
        width: 140px;
        .string-1, .string-2, .string-3, .string-4, .string-5, .string-6 {
            height: 16.67%;
            width: 100%;
            border-bottom: 1px solid $slate;
            &.string-1 {
                border-top: 2px solid $slate;
            }
            &.string-6 {
                border-bottom: 2px solid $slate;
                .fret-num {
                    top: 100%;
                    transform: translateY(5px);
                }
            }
            .outside {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 25%;
                height: 100%;
                text-align: center;
                left: 0;
                transform: translate(-100%, 0);
                .opened {
                    height: 15px; 
                    width: 15px;
                    border: 1px solid black;
                    border-radius: 100%;
                }
                .closed {
                    height: 15px; 
                    width: 15px;
                    border: 1px solid black;
                    background: black;
                    border-radius: 100%;
                }
            }
            .first, .second, .third, .fourth {
                display: flex;
                justify-content: center;
                align-items: center;
                border-right: 1px solid $slate;
                width: 25%;
                height: 100%;
                text-align: center;
                &.first {
                    border-left: 2px solid $slate;
                }
                &.fourth {
                    border-right: 2px solid $slate;
                }
                .filled {
                    height: 15px; 
                    width: 15px;
                    border: 1px solid black;
                    background: black;
                    border-radius: 100%;
                }
            }
        }
    }
    &:last-of-type {
        margin-bottom: 100px;
    }
}