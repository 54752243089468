.blog-container {
    margin-top: 40px;
    margin-bottom: 0;
    max-width: 650px;

    .hero {
        width: 100%;
        height: 400px;
        margin-bottom: 32px;
        @include down($mobile-xl) {
            height: unset;
        }
    }

    .content-img {
        width: 100%;
        height: 400px;
        margin-bottom: 8px;
        margin-top: 8px;
        @include down($mobile-xl) {
            height: unset;
        }
    }

    .title {
        margin-bottom: 4px;
        font-size: 20px;
    }

    .author {
        margin-bottom: 4px;
        font-size: 12px;
    }

    .date {
        margin-bottom: 24px;
        color: $gray;
        font-size: 12px;
    }

    .list-item {
        margin-bottom: 16px;
        list-style: none;
        font-size: 14px;
        &:last-of-type {
            margin-bottom: 0;
        }

        .section-heading {
            font-size: 16px;
            margin-bottom: 4px;
        }
    }

    .nested-list {
        list-style: none;
        margin-bottom: 8px;
        &:last-of-type {
            margin-bottom: 0;
        }

        .nested-list-item-text {
            margin-bottom: 8px;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .list-item-text {
        margin-bottom: 12px;
        line-height: 1.3;
        letter-spacing: 1.01px;
    }

    .caption {
        margin-bottom: 24px;
    }
}
