@keyframes slide-in {
    from {
        left: -100%;
    }
    to {
        left: 0;
    }
}

@keyframes slide-out {
    from {
        left: 0;
    }
    to {
        left: -100%;
        display: none;
    }
}

.overlay {
    width: 100%;
    height: 100vh;
    background: $overlay;
    backdrop-filter: blur(16px);
    position: absolute;
    top: 0;
    z-index: 4;
    display: none;

    &.show {
        display: block;
    }
}

.slide-out {
    top: 0;
    left: -100%;
    width: calc(100% - 100px);
    min-width: 250px;
    max-width: 400px;
    height: 100%;
    position: absolute;
    background: $white;
    z-index: 5;
    border-right: solid $black 2px;
    opacity: 0.9;

    @include up($large-tablet) {
        display: none;
    }

    &.opened {
        left: 0;
        animation: slide-in 1s ease-in-out;
    }

    &.closed {
        left: -100%;
        animation: slide-out 1s ease-in-out;
    }

    .close-btn {
        position: absolute;
        top: 20px;
        right: 20px;
        background: transparent;
        border: none;
        cursor: pointer;

        .close-btn-logo {
            width: 15px;
            height: 15px;
        }
    }

    .route-list {
        margin-top: 60px;
        overflow-y: scroll;
        max-height: calc(100% - 125px);

        li {
            padding: 16px;
            font-size: 24px;
            letter-spacing: 1.5px;
            border-bottom: 1px solid $slate;
            cursor: pointer;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            min-height: 83.5px;
            @include down($large-tablet) {
                font-size: 16px;
            }

            img, .img-placeholder {
                width: 50px;
                margin-right: 20px;
            }

            a {
                color: $black;
                text-decoration: none;
                width: 100%;
            }

            &:first-of-type {
                border-top: 1px solid $slate;
            }

            &.match-route {
                border-right: solid #0fb5ee 10px;
            }
        }
    }
    
    @supports (-webkit-touch-callout: none) {
        min-height: -webkit-fill-available;
        height: calc(100% - constant(safe-area-inset-top) - constant(safe-area-inset-bottom)); /* iOS 11.0 */
        height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom)); /* iOS 11.2 */
    }
}