@keyframes bounce-small {
    0% {
        transform: translate(-54%, 30%);
    }
    50% {
        transform: translate(-54%, -50%);
    }
    100% {
        transform: translate(-54%, 30%);
    }
}

@keyframes bounce-large {
    0% {
        transform: translate(-28%, 30%);
    }
    50% {
        transform: translate(-28%, -50%);
    }
    100% {
        transform: translate(-28%, 30%);
    }
}

@keyframes bounce-above-99 {
    0% {
        transform: translate(-18%, 30%);
    }
    50% {
        transform: translate(-18%, -50%);
    }
    100% {
        transform: translate(-18%, 30%);
    }
}

@keyframes drop-small {
    from {
        opacity: 0;
        transform: translate(-54%, -50%);
    }
    to {
        opacity: 1;
        transform: translate(-54%, 30%);
    }
}

@keyframes drop-large {
    from {
        opacity: 0;
        transform: translate(-28%, -50%);
    }
    to {
        opacity: 1;
        transform: translate(-28%, 30%);
    }
}

@keyframes drop-above-99 {
    from {
        opacity: 0;
        transform: translate(-18%, -50%);
    }
    to {
        opacity: 1;
        transform: translate(-18%, 30%);
    }
}

.navigation {
    display: flex;
    padding: 16px;
    width: 100%;
    z-index: 3;
    background: $white;

    &.transparent {
        opacity: 0.65;
    }

    &.desktop {
        position: fixed;
        justify-content: space-between;
        align-items: center;
        @include down($large-tablet) {
            display: none;
        }
    }

    &.mobile {
        justify-content: center;
        padding: 8px;
        position: fixed;
        @include up($large-tablet) {
            display: none;
        }
    }

    .link,
    .company-logo-link {
        text-decoration: none;
        color: $black;
        padding-bottom: 4px;
        font-size: 20px;
        text-transform: uppercase;
        position: relative;

        &:hover {
            color: $gray;
        }

        &.match-route {
            text-decoration: underline 6px white;
            text-underline-offset: 4px;
            color: $gray;
        }
    }

    .company-logo-link {
        padding-bottom: 0;
    
        .company-logo {
            width: 40px;
            height: 40px;
        }
    }

    .hamburger-logo {
        width: 30px;
        height: 30px;
        position: fixed;
        top: 30px;
        left: 30px;
        cursor: pointer;
        z-index: 3;
        @include down($large-tablet) {
            width: 18px;
            height: 18px;
            top: 18px;
            left: 18px;
        }
    }

    .link-container {
        width: 600px;
        display: flex;
        justify-content: space-evenly;
    
        li {
            list-style: none;
            display: flex;
            justify-content: center;
            align-items: center;
    
            &.match-route {
                a {
                    color: $gray;
                }
            }
        }
    }
    
    .match-route {
        text-decoration: underline 2px $gray;
        text-underline-offset: 4px;
    }
}
