.codebreaker-board {
    display: flex;
    margin-top: 64px;
    margin-bottom: 64px;
    max-width: 350px;
    @include down($large-tablet) {
        flex-direction: column;
        max-width: unset;
        align-items: center;
    }
    @include down($mobile) {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .left-selection-container {
        width: 100%;
        margin-right: 40px;
        @include down($desktop) {
            margin-right: 32px;
        }
        @include down($large-tablet) {
            max-width: 350px;
            margin-right: 0;
        }

        .answer-container {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            border-radius: 6px;
            width: 100%;
            margin-top: 16px;
            margin-bottom: 16px;
            .answer-circle {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 24px;
                font-weight: 600;
                height: 40px;
                width: 40px;
                border-radius: 50%;
                border: solid 1px $black;
                box-shadow: 1px 1px 6px 1px $black;
                @include down($mobile) {
                    height: 32px;
                    width: 32px;
                }
            }
        }

        .possible-colors-container {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 100%;
            margin-top: 16px;
            margin-bottom: 16px;

            .color-option {
                // TODO add drag and drop?
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 24px;
                font-weight: 600;
                height: 40px;
                width: 40px;
                border-radius: 50%;
                border: solid 1px $black;
                box-shadow: 1px 1px 6px 1px $black;
                @include down($mobile) {
                    height: 32px;
                    width: 32px;
                }
            }
        }

        .current-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin-top: 16px;

            .current-guesses-container {
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                width: 100%;
                margin-bottom: 16px;
                background: $gray;
                padding: 12px 4px;
                border-radius: 8px;
                @include down($extra-small) {
                    flex-wrap: wrap;
                    justify-content: space-evenly;
                }

                .current-guess {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    @include down($extra-small) {
                        width: 50%;
                        margin-bottom: 8px;
                    }
                    .current-circle {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: $white;
                        z-index: 1;
                        font-size: 24px;
                        font-weight: 600;
                        height: 40px;
                        width: 40px;
                        border-radius: 50%;
                        border: solid 1px $black;
                        box-shadow: 1px 1px 6px 1px $black;
                        margin-bottom: 8px;
                        @include down($mobile) {
                            height: 32px;
                            width: 32px;
                        }

                        &.selected-color {
                            color: $white;
                            text-shadow: 2px 1px 12px $black;
                        }
                    }

                    .color-dropdown {
                        outline: none;
                        border: solid $black 1px;
                        padding: 4px;
                        margin-left: 4px;
                        margin-right: 4px;
                        cursor: pointer;
                        text-transform: capitalize;
                    }
                }
            }

            .btn-container {
                display: flex;
                justify-content: space-evenly;
                width: 100%;
                margin-bottom: 24px;

                .default-btn {
                    &:disabled {
                        opacity: 0.2;
                        cursor: unset;
                    }
                }
            }
        }
    }

    .right-display-container {
        @include down($large-tablet) {
            max-width: 200px;
        }

        .code-guesses-container {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            max-height: 350px;
            margin-top: 16px;
            @include down($large-tablet) {
                max-height: 300px;
                align-content: space-evenly;
            }
            @include down($mobile) {
                max-height: 250px;
            }
            @include down($small-mobile) {
                max-height: unset;
            }

            .guess-container {
                display: flex;
                align-items: center;
                width: 100%;
                height: 70px;
                margin-right: 24px;
                border-bottom: 1px solid $black;
                &:nth-of-type(5n + 1) {
                    border-top: 1px solid $black;
                }
                &.opacity {
                    opacity: 0.2;
                    cursor: unset;
                }
                @include down($small-desktop) {
                    max-width: 200px;
                }
                @include down($large-tablet) {
                    margin-top: 0;
                    height: 60px;
                    &:nth-of-type(6) {
                        margin-right: 0;
                    }
                    &:nth-of-type(7) {
                        margin-right: 0;
                    }
                    &:nth-of-type(8) {
                        margin-right: 0;
                    }
                    &:nth-of-type(9) {
                        margin-right: 0;
                    }
                    &:nth-of-type(10) {
                        margin-right: 0;
                    }
                }
                @include down($mobile) {
                    height: 50px;
                    max-width: 160px;
                    margin-right: 16px;
                }
                @include down($small-mobile) {
                    max-width: unset;
                    margin-right: 0;
                }

                .guess-number {
                    font-weight: 600;
                    margin-right: 8px;
                    min-width: 12px;
                }

                .right-side-guess-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    min-width: calc(100% - 25px);
                    @include between($large-tablet, $small-desktop) {
                        min-width: calc(100% - 10px);
                    }
                }

                .guess-holder {
                    display: flex;
                    align-items: center;
                    background: $white;
                    height: 100%;
                    width: 160px;
                    @include down($desktop) {
                        width: 115px;
                    }
                    @include down($mobile) {
                        width: 85px;
                    }

                    .code-guess {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 24px;
                        font-weight: 600;
                        height: 32px;
                        width: 32px;
                        border-radius: 50%;
                        border: solid 1px $black;
                        box-shadow: 1px 1px 6px 1px $black;
                        &:not(:last-of-type) {
                            margin-right: 8px;
                        }
                        @include down($desktop) {
                            height: 24px;
                            width: 24px;
                        }
                        @include down($mobile) {
                            height: 16px;
                            width: 16px;
                        }
                    }
                }

                .indicator-holder {
                    display: flex;
                    flex-wrap: wrap;
                    background: $white;
                    width: 60px;
                    @include down($large-tablet) {
                        width: 45px;
                    }

                    .indicator {
                        border-radius: 50%;
                        height: 16px;
                        width: 16px;
                        margin: 5px;
                        border: 1px solid $black;
                        box-shadow: 1px 1px 6px 1px $black;
                        @include down($desktop) {
                            height: 12px;
                            width: 12px;
                        }
                    }
                }
            }
        }
    }

    .pink {
        background: $pink !important;
    }
    
    .yellow {
        background: $yellow !important;
    }
    
    .blue {
        background: $skyblue !important;
    }
    
    .green {
        background: $green !important;
    }
    
    .purple {
        background: $purple !important;
    }
    
    .red {
        background: $red !important;
    }
    
    .white {
        background: $white !important;
    }
    
    .orange {
        background: $orange !important;
    }
    
    .lightgray {
        background: $lightgray !important;
    }
}

