&.blogs {
    .container {
        .heading {
            @include down($mobile) {
                padding-left: 0;
            }
        }
    }

    .blog-list {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        @include down($large-tablet) {
            justify-content: space-between;
        }
        @include down($mobile-xl) {
            justify-content: center;
        }
    }
}
