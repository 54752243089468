@import "./amusement";
@import "./battleship";
@import "./blackjack";
@import "./cards";
@import "./card-display";
@import "./checkerboard";
@import "./checkers";
@import "./codebreaker";
@import "./connect-4";
@import "./direction-note"; // this is for checkerboards where it is important to know which direction is allowed
@import "./instructions-builder";
@import "./keyboard";
@import "./mancala";
@import "./mathlympics";
@import "./number-royale";
@import "./rebus";
@import "./scroll-button";
@import "./secret-number";
@import "./sinker";
@import "./slider";
@import "./slider-puzzle-builder";
@import "./slider-puzzle-global";
@import "./tic-tac-toe";
@import "./timer";
@import "./waiting-room";
@import "./war";
@import "./wordle";

.turn-marker {
    height: 16px;
    width: 40px;
}