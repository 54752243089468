.guitar-101 {
    max-width: 100vw;
    overflow-x: hidden;
    @include down($tablet) {
        overflow-y: hidden;
        min-height: 100vh;
    }
    .selector-box-holder {
        margin: 0 auto;
        height: 200px;
        max-width: 700px;
        min-width: 600px;
        padding: 10px;
        border-radius: 4px;
        border: solid 1px $black;
        background: $black;
        color: $white;
        .btn-holder {
            width: 120px;
        }
        .chords-heading, .notes-heading {
            font-size: 16px;
            margin-right: 8px;
            color: $white;
        }
        .generate-btn, .transpose-btn, .toggle-btn {
            box-shadow: #494956 -1px -1px 5px 1px;
            .circular-arrows {
                border-radius: 50%;
                height: 18px;
                width: 18px;
                position: relative;
                &::before {
                    content: '';
                    box-sizing: border-box;
                    position: absolute;
                    border: 2px solid $orange;
                    width: 100%;
                    height: 7px;
                    top: 0;
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;
                    border-bottom: 0;
                }
                &::after {
                    content: '';
                    box-sizing: border-box;
                    position: absolute;
                    border: 2px solid $orange;
                    width: 100%;
                    height: 7px;
                    bottom: 0;
                    transform: rotate(180deg);
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;
                    border-bottom: 0;
                }
                .triangle {
                    color: $orange;
                    font-weight: 600;
                    &::before {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translate(35%, -100%);
                        border-top: solid 4px $orange;
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: 50%;
                        transform: translate(-35%, 100%) rotate(180deg);
                        border-top: solid 4px $orange;
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                    }
                }
            }
            @include down($mobile) {
                padding: 4px 10px;
            }
        }
        .table-holder {
            height: 80%;
            padding: 5px 10px;
            border-radius: 4px;
            .table-section {
                height: 100%;
                &:not(:first-of-type) {
                    margin-left: 10px;
                    @include down($mobile) {
                        margin-left: 0;
                    }
                }
                .table-heading {
                    color: $white;
                    font-size: 16px;
                    @include down($tablet) {
                        font-size: 14px;
                    }
                    @include down($mobile-xl) {
                        font-size: 12px;
                    }
                }
                .letter-table, .type-table {
                    height: 80%;
                }
                .column-section {
                    margin-right: 15px;
                    @include down($mobile-xl) {
                        margin-right: 10px;
                    }
                    @include down($mobile-xl) {
                        margin-right: 8px;
                    }
                    .radio-holder {
                        height: 25%;
                        display: flex;
                        align-items: center;
                        input[type=radio] {
                            cursor: pointer;
                        }
                        label {
                            color: $white;
                            font-size: 16px;
                            display: inline-block;
                            margin-left: 5px;
                            cursor: pointer;
                            @include down($tablet) {
                                font-size: 14px;
                            }
                            @include down($mobile-xl) {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
            .dividing-line {
                height: 90%;
                width: 1.6px;
                background: $orange;
                margin: auto 0;
            }
        }
        @include down($tablet) {
            max-width: 580px;
            min-width: 500px;
        }
        @include down($mobile-xl) {
            max-width: 499px;
            min-width: 400px;
        }
        @include down($mobile) {
            max-width: 320px;
            min-width: 320px;
            height: 290px;
            min-height: 290px;
            justify-content: unset;
            &.selector-notes-holder {
                height: 175px;
                min-height: 175px;
            }
            &.notes-holder {
                height: 175px;
            }
            .heading-container {
                margin-bottom: 8px;
            }   
            .table-holder {
                flex-wrap: wrap;
                flex-grow: 1;
                .table-section {
                    height: unset;
                    .letter-table, .type-table {
                        height: 90px;
                        margin-bottom: 10px;
                        &:last-of-type {
                            margin-bottom: 0;
                        }   
                        .radio-holder {
                            height: 22%; 
                        }
                    }
                }
                .dividing-line {
                    height: 90px;
                    margin: unset;
                    margin-right: 10px;
                }
            }
            .mobile-hide {
                display: none;
            }
            .mobile-show {
                width: calc(100% - 40px);
                background: $orange;
                height: 1px;
                margin-bottom: 10px;
            }
        }
    }
    .guitar {
        margin-top: 50px;
        margin-left: 15px;
        overflow-x: hidden;
        @include down($tablet) {
            transform: rotate(38deg);
            margin-top: 75px;
            margin-left: 0;
            overflow: unset;
        }
        @include down($mobile-xl) {
            margin-top: 0;
        }
        @include down($small-mobile) {
            transform: rotate(90deg);
        }
        .head-container {
            position: relative;
            .peg {
                background: $gray;
                width: 15px;
                height: 15px;
                position: absolute;
                clip-path: polygon(0% 0%, 0% 70%, 30% 70%, 30% 100%, 70% 100%, 70% 70%, 100% 70%, 100% 0%);
                @include down($desktop) {
                    width: 12px;
                    height: 12px;
                }
                @include down($tablet) {
                    width: 9px;
                    height: 9px;
                }
                @include down($mobile-xl) {
                    width: 12px;
                    height: 12px;
                }
                @include down($mobile) {
                    width: 12px;
                    height: 12px;
                }
                &.peg-e-high {
                    top: -7%;
                    left: 62%;
                }
                &.peg-b {
                    top: -3%;
                    left: 37.5%;
                }
                &.peg-g {
                    top: 2%;
                    left: 13%;
                }
                &.peg-d {
                    left: 13%;
                    bottom: 2%;
                    transform: rotate(180deg);
                }
                &.peg-a {
                    bottom: -3%;
                    left: 37.5%;
                    transform: rotate(180deg);
                }
                &.peg-e-low {
                    bottom: -7%;
                    left: 62%;
                    transform: rotate(180deg);
                }
            }
        }
        .head {
            position: relative;
            width: 200px;
            height: 130px;
            background: $guitar-head;
            clip-path: polygon(0 50%, 4% 85%, 80% 100%, 85% 90%, 100% 88%, 100% 12%, 85% 10%, 80% 0%, 4% 15%, 0 50%);
            @include down($desktop) {
                width: 125px;
                height: 100px;
            }
            @include down($tablet) {
                width: 100px;
                height: 80px;
            }
            @include down($mobile-xl) {
                width: 110px;
                height: 105px;
            }
            @include down($mobile) {
                width: 110px;
                height: 105px;
            }
            .tuning-peg {
                position: absolute;
                background: $gray;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                @include down($desktop) {
                    width: 12px;
                    height: 12px;
                }
                @include down( $tablet) {
                    width: 10px;
                    height: 10px;
                }
                @include down($mobile-xl) {
                    width: 7px;
                    height: 7px;
                }
                @include down($mobile) {
                    width: 5px;
                    height: 5px;
                }

                &.peg-e-high {
                    top: 8%;
                    left: 65%;
                }
                &.peg-b {
                    top: 15%;
                    left: 40%;
                }
                &.peg-g {
                    top: 20%;
                    left: 15%;
                }
                &.peg-d {
                    bottom: 20%;
                    left: 15%;
                }
                &.peg-a {
                    bottom: 15%;
                    left: 40%;
                }
                &.peg-e-low {
                    bottom: 8%;
                    left: 65%;
                }
            }
            .string {
                background: $gray;
                position: absolute;
                z-index: 1;
                right: 0;
                &.string-e-high-part-1 {
                    width: 28%;
                    height: 2px;
                    top: 13%;
                    right: 5px;
                    transform: rotate(8deg) translate(0px, -2px);
                    @include down($tablet) {
                        transform: rotate(8deg) translate(0px, -1px);
                    }
                    @include down($mobile) {
                        height: 1px;
                        right: 3px;
                        transform: rotate(12deg) translate(0px, -1.5px);
                        width: 30%;
                    }
                }
                &.string-e-high-part-2 {
                    width: 5px;
                    height: 2px;
                    top: 14.5%;
                    @include down($mobile) {
                        height: 1px;
                        width: 3px;
                        top: 15%;
                    }
                }
                &.string-b-part-1 {
                    width: 52%;
                    right: 5px;
                    height: 2.1px;
                    top: 27%;
                    transform: rotate(6.5deg) translate(0px, -6px);
                    @include down($desktop) {
                        transform: rotate(6.5deg) translate(0px, -4px);
                    }
                    @include down($tablet) {
                        transform: rotate(6.5deg) translate(0px, -3px);
                    }
                    @include down($mobile-xl) {
                        transform: rotate(9deg) translate(0px, -5px);
                    }
                    @include down($mobile) {
                        height: 1px;
                        right: 3px;
                        transform: rotate(10deg) translate(0px, -5.5px);
                        width: 56%;
                    }
                }
                &.string-b-part-2 {
                    height: 2.1px;
                    width: 5px;
                    top: 27%;
                    @include down($desktop) {
                        top: 27%;
                    }
                    @include down($mobile) {
                        height: 1px;
                        width: 3px;
                    }
                }
                &.string-g-part-1 {
                    width: 78%;
                    height: 2.2px;
                    right: 5px;
                    top: 43%;
                    transform: rotate(10deg) translate(-1px, -14px);
                    @include down($desktop) {
                        transform: rotate(10deg) translate(-2px, -12px);
                    }
                    @include down($tablet) {
                        transform: rotate(10deg) translate(-1px, -9px);
                    }
                    @include down($mobile-xl) {
                        transform: rotate(12deg) translate(-1px, -12px);
                    }
                    @include down($mobile) {
                        height: 1px;
                        right: 3px;
                        transform: rotate(13deg) translate(-1.5px, -13px);
                        width: 82.5%;
                    }
                }
                &.string-g-part-2 {
                    width: 5px;
                    height: 2.2px;
                    top: 43%;
                    @include down($desktop) {
                        top: 39%;
                    }
                    @include down($mobile-xl) {
                        top: 40%;
                    }
                    @include down($mobile) {
                        height: 1px;
                        width: 3px;
                    }
                }
                &.string-d-part-1 {
                    width: 78%;
                    height: 2.3px;
                    bottom: 43%;
                    right: 5px;
                    transform: rotate(-10deg) translate(-1px, 14px);
                    @include down($desktop) {
                        transform: rotate(-10deg) translate(-1px, 11px);
                    }
                    @include down($tablet) {
                        transform: rotate(-10deg) translate(-1px, 9px);
                    }
                    @include down($mobile-xl) {
                        transform: rotate(-12deg) translate(-1px, 11px);
                    }
                    @include down($mobile) {
                        height: 1.1px;
                        right: 3px;
                        transform: rotate(-13deg) translate(-1.5px, 12.5px);
                        width: 82.5%;
                    }
                }
                &.string-d-part-2 {
                    width: 5px;
                    height: 2.2px;
                    bottom: 43%;
                    @include down($desktop) {
                        bottom: 41%;
                    }
                    @include down($mobile) {
                        height: 1.1px;
                        width: 3px;
                    }
                }
                &.string-a-part-1 {
                    width: 53%;
                    height: 2.4px;
                    bottom: 27%;
                    right: 5px;
                    transform: rotate(-6.5deg) translate(0px, 6px);
                    @include down($desktop) {
                        transform: rotate(-6.5deg) translate(0px, 4px);
                    }
                    @include down($tablet) {
                        transform: rotate(-6.5deg) translate(0px, 3.2px);
                    }
                    @include down($mobile-xl) {
                        transform: rotate(-7.5deg) translate(0px, 5px);
                    }
                    @include down($mobile) {
                        width: 57%;
                        height: 1.2px;
                        right: 3px;
                        transform: rotate(-10deg) translate(0px, 6.5px);
                    }
                }
                &.string-a-part-2 {
                    width: 5px;
                    height: 2px;
                    bottom: 27%;
                    @include down($desktop) {
                        bottom: 26%;
                    }
                    @include down($mobile-xl) {
                        bottom: 27%;
                    }
                    @include down($mobile) {
                        height: 1.1px;
                        width: 3px;
                        bottom: 27%;
                    }
                }
                &.string-e-low-part-1 {
                    width: 30%;
                    height: 2px;
                    bottom: 15%;
                    right: 5px;
                    transform: rotate(-6deg) translate(0px, 3px);
                    @include down($desktop) {
                        transform: rotate(-6deg) translate(0px, 1px);
                    }
                    @include down($tablet) {
                        transform: rotate(-6deg) translate(1px, 0.5px);
                    }
                    @include down($mobile-xl) {
                        transform: rotate(-7deg) translate(1px, 2px);
                    }
                    @include down($mobile) {
                        height: 1.3px;
                        right: 3px;
                        transform: rotate(-11.5deg) translate(0px, 3.5px);
                        width: 31%;
                    }
                }
                &.string-e-low-part-2 {
                    width: 5px;
                    height: 2.6px;
                    bottom: 15.5%;
                    @include down($mobile-xl) {
                        height: 1.4px;
                    }
                    @include down($mobile) {
                        width: 3px;
                        height: 1.1px;
                        bottom: 15.5%;
                    }
                }
            }
            .nut {
                background: $white;
                width: 5px;
                height: 100%;
                position: absolute;
                right: 0;
                @include down($mobile) {
                    width: 3px;
                } 
            }
        }
        .neck {
            height: 100px;
            background: $guitar-neck;
            position: relative;
            z-index: 2;
            @include down($desktop) {
                height: 75px;
            }
            @include down($tablet) {
                height: 60px;
            }
            @include down($mobile-xl) {
                height: 80px;
            }
            @include down($mobile) {
                height: 80px;
            }
            .note-circle, .note-circle-string-1, .note-circle-string-2, .note-circle-string-3, .note-circle-string-4, .note-circle-string-5, .note-circle-string-6 {
                background: $orange;
                border-radius: 50%;
                height: 20px;
                width: 20px;
                min-height: 20px;
                min-width: 20px;
                font-size: 10px;
                color: $white;
                text-transform: uppercase;
                position: absolute;
                &.note-circle-string-1, &.note-circle-string-2, &.note-circle-string-3, &.note-circle-string-4, &.note-circle-string-5, &.note-circle-string-6 {
                    left: -5px;
                    z-index: 2;
                    transform: translate(-100%, 0);
                    .note-circle {
                        height: 100%;
                        width: 100%;
                    }
                    @include down($mobile) {
                        left: -3px;
                    }
                }
                @include down($desktop) {
                    font-size: 8px; 
                    width: 15px;
                    height: 15px;
                    min-height: 15px;
                    min-width: 15px;
                }
                @include down($tablet) {
                    font-size: 6px; 
                    width: 12px;
                    height: 12px;
                    min-height: 12px;
                    min-width: 12px;
                }
                @include down($mobile-xl) {
                    font-size: 8px; 
                    width: 15px;
                    height: 15px;
                    min-height: 15px;
                    min-width: 15px;
                }
            }
            .note-circle {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .note-circle-string-1 {
                top: -7%;
            }
            .note-circle-string-2 {
                top: 9%;
            }
            .note-circle-string-3 {
                top: 28%;
            }
            .note-circle-string-4 {
                bottom: 28%;
            }
            .note-circle-string-5 {
                bottom: 13%;
            }
            .note-circle-string-6 {
                bottom: -3%;
            }
            .string-1, .string-2, .string-3, .string-4, .string-5, .string-6 {
                min-height: 10px;
                cursor: pointer;
                transform: translateY(-25%);
                position: relative;
                background: transparent;
                outline: none;
                border: unset;
                @include down($desktop) {
                    min-height: 7.5px;
                }
                @include down($tablet) {
                    min-height: 6px;
                }
                @include down($mobile-xl) {
                    min-height: 10px;
                    transform: translateY(-40%);
                }
                @include down($mobile) {
                    min-height: 5px;
                }
            }
            .bar {
                height: 100px;
                background: $orange;
                width: 5px;
                position: absolute;
                left: 25px;
                z-index: 1000;
                top: -4%;
                transform: translate(-50%, 0);
                @include down($desktop) {
                    height: 75px;
                    left: 20px;
                }
                @include down($tablet) {
                    height: 60px;
                    left: 17px;
                    top: 2%;
                }
                @include down($mobile-xl) {
                    height: 45px;
                    left: 15px;
                    top: 22%;
                }
                @include down($mobile) {
                    height: 32px;
                    left: 10px;
                }
            }
            .x-string-1, .x-string-2, .x-string-3, .x-string-4, .x-string-5, .x-string-6 {
                border-radius: 50%;
                height: 20px;
                min-height: 20px;
                min-width: 20px;
                font-size: 15px;
                color: $red;
                text-transform: uppercase;
                position: absolute;
                left: 0px;
                z-index: 2;
                transform: translate(-100%, 0);
                @include down($desktop) {
                    font-size: 12px;
                    left: -1px;
                }
                @include down($tablet) {
                    font-size: 10px;
                }
                @include down($mobile-xl) {
                    font-size: 10px;
                }
                @include down($mobile) {
                    left: -3px;
                    font-size: 10px;
                }
            }
            .x-string-1 {
                top: -4.7%;
                @include down($mobile-xl) {
                    left: 8px;
                }
            }
            .x-string-2 {
                top: 12%;
                @include down($mobile-xl) {
                    left: 8px;
                }
            }
            .x-string-3 {
                top: 30%;
                @include down($desktop) {
                    top: 29%;
                }
                @include down($mobile-xl) {
                    left: 8px; 
                }
            }
            .x-string-4 {
                bottom: 30%;
                @include down($mobile-xl) {
                    left: 8px;
                    bottom: 22%;
                }
                @include down($mobile) {
                    bottom: 21%;
                }
            }
            .x-string-5 {
                bottom: 10%;
                @include down($desktop) {  
                    left: 5px;
                    bottom: 3%; 
                }
                @include down($tablet) {
                    bottom: -2%;
                }
                @include down($mobile-xl) {
                    bottom: 2%;
                    left: 8px;
                }
                @include down($mobile) {
                    bottom: 1%;
                }
            }
            .x-string-6 {
                bottom: -5%;
                @include down($desktop) {
                    bottom: -12%;
                    left: 5px;
                }
                @include down($tablet) {
                    bottom: -15%;
                }
                @include down($mobile-xl) {
                    bottom: -12%;
                    left: 8px;
                }
            }

            .fret {
                border-right: 1px solid white;
                display: flex;
                justify-content: center;
                align-items: center;
                .fret-marker {
                    background: $white;
                    width: 10px; 
                    height: 10px;
                    border-radius: 50%;
                    @include down($desktop) {
                        height: 6px;
                        width: 6px;
                    }
                    @include down($tablet) {
                        height: 6px;
                        width: 6px;
                    }
                    @include down($mobile-xl) {
                        height: 4px;
                        width: 4px;
                    }
                    @include down($mobile) {
                        height: 6px;
                        width: 6px;
                    }
                }
            }
            .fret-1 {
                width: 50px;
                @include down($desktop) {
                    width: 40px;
                }
                @include down($tablet) {
                    width: 34px;
                }
                @include down($mobile-xl) {
                    width: 30px;
                }
                @include down($mobile) {
                    width: 25px;
                }
            }
            .fret-2 {
                width: 48.5px;
                @include down($desktop) {
                    width: 39px;
                }
                @include down($tablet) {
                    width: 33px;
                }
                @include down($mobile-xl) {
                    width: 29px;
                }
                @include down($mobile) {
                    width: 24px;
                }
            }
            .fret-3 {
                width: 47px;
                @include down($desktop) {
                    width: 38px;
                }
                @include down($tablet) {
                    width: 32px;
                }
                @include down($mobile-xl) {
                    width: 28px;
                }
                @include down($mobile) {
                    width: 23px;
                }
            }
            .fret-4 {
                width: 45.5px;
                @include down($desktop) {
                    width: 37px;
                }
                @include down($tablet) {
                    width: 31px;
                }
                @include down($mobile-xl) {
                    width: 27px;
                }
                @include down($mobile) {
                    width: 22px;
                }
            }
            .fret-5 {
                width: 44px;
                @include down($desktop) {
                    width: 36px;
                }
                @include down($tablet) {
                    width: 30px;
                }
                @include down($mobile-xl) {
                    width: 26px;
                }
                @include down($mobile) {
                    width: 21.5px;
                }
            }
            .fret-6 {
                width: 42.5px;
                @include down($desktop) {
                    width: 35px;
                }
                @include down($tablet) {
                    width: 29px;
                }
                @include down($mobile-xl) {
                    width: 25px;
                }
                @include down($mobile) {
                    width: 21px;
                }
            }
            .fret-7 {
                width: 41px;
                @include down($desktop) {
                    width: 34px;
                }
                @include down($tablet) {
                    width: 28px;
                }
                @include down($mobile-xl) {
                    width: 24px;
                }
                @include down($mobile) {
                    width: 20.5px;
                }
            }
            .fret-8 {
                width: 39.5px;
                @include down($desktop) {
                    width: 33px;
                }
                @include down($tablet) {
                    width: 27px;
                }
                @include down($mobile-xl) {
                    width: 23px;
                }
                @include down($mobile) {
                    width: 20px;
                }
            }
            .fret-9 {
                width: 38px;
                @include down($desktop) {
                    width: 32px;
                }
                @include down($tablet) {
                    width: 26px;
                }
                @include down($mobile-xl) {
                    width: 22px;
                }
                @include down($mobile) {
                    width: 19.5px;
                }
            }
            .fret-10 {
                width: 36.5px;
                @include down($desktop) {
                    width: 31px;
                }
                @include down($tablet) {
                    width: 25px;
                }
                @include down($mobile-xl) {
                    width: 21px;
                }
                @include down($mobile) {
                    width: 19px;
                }
            }
            .fret-11 {
                width: 35px;
                @include down($desktop) {
                    width: 30px;
                }
                @include down($tablet) {
                    width: 24px;
                }
                @include down($mobile-xl) {
                    width: 20px;
                }
                @include down($mobile) {
                    width: 18.5px;
                }
            }
            .fret-12 {
                width: 33.5px;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                @include down($desktop) {
                    width: 29px;
                }
                @include down($tablet) {
                    width: 23px;
                }
                @include down($mobile-xl) {
                    width: 19px;
                }
                @include down($mobile) {
                    width: 18px;
                }
                .fret-marker {
                    &:first-of-type {
                        margin-bottom: 30px;
                        @include down($desktop) {
                            margin-bottom: 24px;
                        }
                        @include down($tablet) {
                            margin-bottom: 20px;
                        }
                        @include down($mobile-xl) {
                            margin-bottom: 12px;
                        }
                    }
                }
            }
            .fret-13 {
                width: 32px;
                @include down($desktop) {
                    width: 28px;
                }
                @include down($tablet) {
                    width: 22px;
                }
                @include down($mobile-xl) {
                    width: 18px;
                }
                @include down($mobile) {
                    width: 17.5px;
                }
            }
            .fret-14 {
                width: 30.5px;
                @include down($desktop) {
                    width: 27px;
                }
                @include down($tablet) {
                    width: 21px;
                }
                @include down($mobile-xl) {
                    width: 17px;
                }
                @include down($mobile) {
                    width: 17px;
                }
            }
            .fret-15 {
                width: 29px;
                @include down($desktop) {
                    width: 26px;
                }
                @include down($tablet) {
                    width: 20px;
                }
                @include down($mobile-xl) {
                    width: 16px;
                }
                @include down($mobile) {
                    width: 16.5px;
                }
            }
            .fret-16 {
                width: 27.5px;
                @include down($desktop) {
                    width: 25px;
                }
                @include down($tablet) {
                    width: 19px;  
                }
                @include down($mobile-xl) {
                    width: 15px;
                }
                @include down($mobile) {
                    width: 16px;
                }
            }
            .fret-17 {
                width: 26px;
                @include down($desktop) {
                    width: 24px;
                }
                @include down($tablet) {
                    width: 18px;
                }
                @include down($mobile-xl) {
                    width: 14px;
                }
                @include down($mobile) {
                    width: 15.5px;
                }
            }
            .fret-18 {
                width: 24.5px;
                @include down($desktop) {
                    width: 23px;
                }
                @include down($tablet) {
                    width: 17px;
                }
                @include down($mobile-xl) {
                    width: 13px;
                }
                @include down($mobile) {
                    width: 15px;
                }
            }
            .fret-19 {
                width: 23px;
                @include down($desktop) {
                    width: 22px;
                }
                @include down($tablet) {
                    width: 16px;
                }
                @include down($mobile-xl) {
                    width: 12px;
                }
                @include down($mobile) {
                    width: 14.5px;
                }
            }
            .string {
                position: absolute;
                background: $gray;
                width: 100%;
                z-index: 3;
                &.string-e-high {
                    top: 4%;
                    height: 2px;
                    @include down($desktop) {
                        top: 3%;
                    }
                    @include down($mobile) {
                        height: 1px;
                        top: 4%;
                    }
                }
                &.string-b {
                    top: 20%;
                    height: 2.1px;
                    @include down($desktop) {
                        top: 19%;
                    }
                    @include down($mobile) {
                        height: 1px;
                    }
                }
                &.string-g {
                    top: 40%;
                    height: 2.2px;
                    @include down($desktop) {
                        top: 37%;
                    }
                    @include down($mobile) {
                        height: 1px;
                    }
                }
                &.string-d {
                    bottom: 40%;
                    height: 2.2px;
                    @include down($desktop) {
                        bottom: 38%;
                    }
                    @include down($mobile) {
                        height: 1.1px;
                    }
                }
                &.string-a {
                    bottom: 20%;
                    height: 2.6px;
                    @include down($desktop) {
                        bottom: 19%;
                    }
                    @include down($mobile-xl) {
                        height: 2.5px;
                    }
                    @include down($mobile) {
                        height: 1.2px;
                    }
                }
                &.string-e-low {
                    bottom: 5%;
                    height: 2.6px;
                    @include down($desktop) {
                        bottom: 4%;
                    }
                    @include down($mobile-xl) {
                        height: 2.6px;                    
                    }
                    @include down($mobile) {
                        height: 1.3px;
                    }
                }
            }
        }
        .body {
            width: 600px; 
            height: 425px;
            position: relative;
            .body-background {
                background:  $guitar-body;
                position: absolute;
                width: 100%; 
                min-width: 600px;
                height: 425px;
                top: 0%;
                left: -100px;
                border-radius: 140px 140px 140px 140px;
                clip-path: polygon(0% 0%, 30% 0%, 40% 2.5%, 45% 5%, 49.5% 7%, 50% 7%, 51.5% 7%, 55% 5%, 60% 2.5%, 70% 0%, 100% 0%, 100% 100%, 70% 100%, 60% 97.5%, 55% 95%, 51.5% 93%, 50% 93%, 49.5% 93%, 45% 95%, 40% 97.5%, 30% 100%, 0 100%);
                @include down($desktop) {
                    min-width: 425px;
                    height: 350px;
                    top: 50%;
                    transform: translate(0, -50%);
                }
                @include down($tablet) {
                    min-width: 375px;
                    height: 275px;
                }
                @include down($mobile-xl) {
                    left: -60px;
                    min-width: 330px;
                    height: 250px;
                }
            }
            .sound-hole {
                margin-left: 1px;
                height: 160px;
                width: 160px;
                border-radius: 50%;
                background: $black;
                z-index: 1;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                @include down($desktop) {
                    height: 130px;
                    width: 130px;
                }
                @include down($tablet) {
                    height: 100px;
                    width: 100px;
                }
                @include down($mobile-xl) {
                    height: 90px;
                    width: 90px;
                }
            }
            .string {
                position: absolute;
                background: $gray;
                width: 250px;
                left: -5px;
                z-index: 2;
                @include down($desktop) {
                    width: 175px;
                }
                @include down($tablet) {
                    width: 150px;
                }
                @include down($mobile-xl) {
                    width: 130px;
                }
                &.string-e-high {
                    top: 39.2%;
                    height: 2px;
                    @include down($desktop) {
                        top: 41.7%;
                    }
                    @include down($tablet) {
                        top: 43.3%;
                    }
                    @include down($mobile-xl) {
                        height: 1px;
                        top: 41.5%;
                    }
                } 
                &.string-b {
                    top: 43%;
                    height: 2.1px;
                    @include down($desktop) {
                        top: 44.5%;
                    }
                    @include down($tablet) {
                        top: 45.7%;
                    }
                    @include down($mobile-xl) {
                        height: 1px;
                        top: 44.2%;
                    }
                }
                &.string-g {
                    top: 47.8%;
                    height: 2.2px;
                    @include down($desktop) {
                        top: 47.8%; 
                    }
                    @include down($tablet) {
                        top: 48.3%;
                    }
                    @include down($mobile-xl) {
                        height: 1px;
                        top: 47.5%;
                    }
                }
                &.string-d {
                    bottom: 47.8%;
                    height: 2.2px;
                    @include down($desktop) {
                        bottom: 47.8%;
                    }
                    @include down($tablet) {
                        bottom: 48.3%;
                    }
                    @include down($mobile-xl) {
                        height: 1.1px;
                        bottom: 47.7%;
                    }
                }
                &.string-a {
                    bottom: 43%;
                    height: 2.6px;
                    @include down($desktop) {
                        bottom: 44.5%;
                    }
                    @include down($tablet) {                           
                        bottom: 45.6%;
                    }
                    @include down($mobile-xl) {
                        height: 2.5px;
                        height: 1.2px;
                        bottom: 44.3%;
                    }
                }
                &.string-e-low {
                    bottom: 39.2%;
                    height: 2.8px;
                    @include down($desktop) {
                        bottom: 42%;
                    }
                    @include down($tablet) {
                        bottom: 43.5%;
                    }
                    @include down($mobile-xl) {
                        height: 1.1px;
                        bottom: 41.5%;
                    }
                } 
            }
            .bridge {
                position: absolute;
                height: 120px;
                width: 25px;
                background: $black;
                left: 242px;
                top: 50%;
                transform: translate(-50%, -50%);

                @include down($desktop) {
                    height: 90px;
                    width: 20px;
                    left: 169px;
                }
                @include down($tablet) {
                    left: 143px;
                    height: 75px;
                    width: 20px;
                }
                @include down($mobile-xl) {
                    left: 124px;
                    height: 83px;
                    width: 16px;
                }
            }
            .saddle {
                height: 100px;
                width: 3px;
                background: $white;
                position: absolute;
                left: 236px;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
                @include down($desktop) {
                    height: 80px;
                    left: 166px;
                }
                @include down($tablet) {
                    height: 65px;
                    left: 138px;
                }
                @include down($mobile-xl) {
                    height: 80px;
                    left: 120px;
                    width: 1px;
                }
            }
            .bridge-pin {
                position: absolute;
                height: 7px;
                width: 7px;
                border-radius: 50%;
                background: $gray;
                z-index: 1;
                left: 245px;
                @include down($desktop) {
                    left: 170px;
                }
                @include down($tablet) {
                    left: 143px;
                    height: 6px;
                    width: 6px;
                }
                @include down($mobile-xl) {
                    left: 122px;
                    height: 5px;
                    width: 5px; 
                }

                &.string-e-high {
                    top: 38.8%;
                    @include down($desktop) {
                        top: 41%;
                    }
                    @include down($tablet) {
                        top: 42.6%;
                    }
                    @include down($mobile-xl) {
                        top: 41%;
                    }
                }
                
                &.string-b {
                    top: 42.4%;
                    @include down($desktop) {
                        top: 43.7%;
                    }
                    @include down($tablet) {
                        top: 45%;
                    }
                    @include down($mobile-xl) {
                        top: 44%; 
                    }
                }
                
                &.string-g {
                    top: 47.1%;
                    @include down($desktop) {
                        top: 47%;
                    }
                    @include down($tablet) {
                        top: 47.5%;
                    }
                    @include down($mobile-xl) {
                        top: 47%;
                    }
                }
                
                &.string-d {
                    bottom: 47%;
                    @include down($desktop) {
                        bottom: 47%;
                    }
                    @include down($tablet) {
                        bottom: 47.8%;
                    }
                    @include down($mobile-xl) {
                        bottom: 47%; 
                    }
                }
                
                &.string-a {
                    bottom: 42.5%;
                    @include down($desktop) {
                        bottom: 44%;
                    }
                    @include down($tablet) {
                        bottom: 45.1%;
                    }
                    @include down($mobile-xl) {
                        bottom: 44%; 
                    }
                }
                
                &.string-e-low {
                    bottom: 38.5%;
                    @include down($desktop) {
                        bottom: 41.5%;
                    }
                    @include down($tablet) {
                        bottom: 43%;
                    }
                    @include down($mobile-xl) {
                        bottom: 41%;
                    }
                }
            }
        }
    }

    .overlapping-guitar {
        background: $white;
    }
}