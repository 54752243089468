.card-display-board {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    margin: 64px auto 0;
    width: calc(min(100%, 900px));
    @include down($xl-tablet) {
        width: calc(min(100%, 810px));
    }
    @include down($large-tablet) {
        width: calc(min(100%, 720px));
    }
    @include down($tablet) {
        width: calc(min(100%, 630px));
    }
    @include down($mobile-xl) {
        width: calc(min(100%, 540px));
    }
    @include down($mobile) {
        width: calc(min(100%, 450px));
    }
    @include down($small-mobile) {
        width: calc(min(100%, 360px));
    }
    @include down($extra-small) {
        width: calc(min(100%, 270px));
    }
    @include down(300px) {
        width: calc(min(100%, 180px));
    }

    @include down($mobile) {
        margin-top: 40px;
    }

    .button-container {
        margin-bottom: 16px;
        display: flex;
        column-gap: 8px;
    }

    .cards-container {
        display: flex;
        flex-wrap: wrap;

        .playing-card {
            margin: 4px;
        }
    }
}