.timer-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .timer {
        background: $black;
        padding: 4px 24px;
        color: $green;
        letter-spacing: 2px;
        font-size: 60px;
        margin-bottom: 12px;
    }
    .start-button {
        border-radius: 50%;
        height: 60px;
        width: 60px;
        color: $lightgreen;
        background: $darkgreen;
        letter-spacing: 1.5px;
        padding: 8px;
    }
    .countdown {
        font-size: 40px;
        color: $lightgreen;
    }
}