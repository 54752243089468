&.products {
    .container {
        .heading {
            @include down($mobile) {
                padding-left: 0;
            }
        }
    }

    .product-list, .resource-list {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        min-height: calc(100vh - 300px);
        &:not(:last-of-type) {
            margin-bottom: 40px;
        }

        @include down($large-tablet) {
            justify-content: space-between;
        }
        @include down($mobile-xl) {
            justify-content: center;
        }
    }
}
