.blackjack-board {
    margin-top: 64px;
    @include down($mobile) {
        margin-top: 40px;
    }

    .game-table {
        position: relative;
        background: $darkgreen;
        border-radius: 8px;
        height: 400px;
        width: 800px;
        @include down($desktop) {
            width: 100%;
        }
        @include down($mobile-xl) {
            height: 350px;
        }
        @include down($mobile) {
            display: flex;
            flex-wrap: wrap;
            height: 550px;
            justify-content: center;
        }
        @include down($small-mobile) {
            flex-direction: column;
            flex-wrap: unset;
            align-items: center;
            height: 850px;
            justify-content: space-evenly;
        }
    }

    .player-cards {
        display: flex;
        align-items: center;
        position: absolute;
        height: 100px;
        width: 150px;
        @include down($small-mobile) {
            width: 175px;
        }

        &.hand-1 {
            top: 32px;
            left: 16%;
            transform: translate(-50%, 25%) rotate(80deg);
            @include down($mobile-xl) {
                top: 20px;
                transform: translate(-45%, 25%) rotate(90deg);
            }
            @include down($mobile) {
                position: relative;
                top: unset;
                left: unset;
                transform: unset;
                margin-top: 40px;
                margin-left: 0;
            }
            @include down($small-mobile) {
                margin-top: 0px;
            }
        }

        &.hand-2 {
            bottom: 75px;
            left: 33.33%;
            transform: translate(-60%, 0%) rotate(40deg);
            @include down($mobile-xl) {
                bottom: 60px;
                left: 30%;
                transform: translate(-60%, 25%);
            }
            @include down($mobile) {
                position: relative;
                bottom: unset;
                left: unset;
                transform: unset;
                margin-top: 40px;
                margin-left: 40px;
            }
            @include down($small-mobile) {
                margin-left: 0;
                margin-top: 24px;
            }
        }

        &.hand-3 {
            bottom: 50px;
            left: 60%;
            transform: translate(-30%, 0%) rotate(-15deg);
            @include down($mobile-xl) {
                bottom: 60px;
                left: 66.66%;
                transform: translate(-30%, 25%);
            }
            @include down($mobile) {
                position: relative;
                bottom: unset;
                left: unset;
                transform: unset;
                margin-top: 16px;
            }
            @include down($small-mobile) {
                margin-top: 24px;
            }
        }

        &.hand-4 {
            top: 64px;
            left: 80%;
            transform: translate(-30%, 30%) rotate(-75deg);
            @include down($mobile-xl) {
                top: 24px;
                left: 76%;
                transform: translate(-30%, 30%) rotate(-90deg);
            }
            @include down($mobile) {
                position: relative;
                top: unset;
                left: unset;
                transform: unset;
                margin-left: 40px;
                margin-top: 16px;
            }
            @include down($small-mobile) {
                margin-left: 0;
                margin-top: 24px;
            }
        }

        &.hand-5 {
            left: 49%;
            top: 48px;
            transform: translate(-50%, 0);
            @include down($mobile) {
                position: relative;
                top: unset;
                left: unset;
                transform: unset;
                margin-top: 16px;
            }
            @include down($small-mobile) {
                margin-top: 24px;
            }
        }

        .player-name {
            position: absolute;
            top: -18px;
            left: 50%;
            transform: translate(-50%, 0);
            font-size: 12px;
            color: $white;
            letter-spacing: 2.5px;
        }

        .playing-card {
            position: absolute;
            top: 0;
            &.card-position-0 {
                left: 0;
            }
            &.card-position-1 {
                left: 14%;
            }
            &.card-position-2 {
                left: 28%;
            }
            &.card-position-3 {
                left: 40%;
            }
            &.card-position-4 {
                left: 54%;
            }
        }

        .button-container {
            position: absolute;
            bottom: -4px;
            left: 50%;
            width: 100%;
            transform: translate(-54%, 100%);
            display: flex;
            justify-content: space-evenly;

            button {
                padding: 4px 8px;
                font-size: 10px;
                cursor: not-allowed;
                &.selectable {
                    cursor: pointer;
                }
                &:disabled {
                    background: $disabled;
                    color: $black;
                }
            }
        }
    }
}
