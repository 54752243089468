.outer-container {
    background-color: transparent;
    height: 42.5px;
    perspective: 1000px;

    &:hover .inner-container {
        transform: rotateX(180deg);
    }

    .inner-container {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.8s;
        transform-style: preserve-3d;
    }

    .displayed-btn,
    .hovered-btn {
        position: absolute;
        font-size: 16px;
        border-radius: 4px;
        border: none;
        cursor: pointer;
        color: $white;
        letter-spacing: 0.75px;
        text-transform: uppercase;
        height: 42px;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        padding: 10px 16px;
        text-shadow: 1px 1px 10px $black;
        letter-spacing: 1.25px;
        width: 210px;

        &:disabled {
            opacity: 0.25;
            cursor: unset;
        }
    }

    .displayed-btn {
        &.blue {
            background: $blue;
        }

        &.skyblue {
            background: $skyblue;
        }

        &.green {
            background: $green;
        }

        &.orange {
            background: $orange;
        }

        &.red {
            background: $red;
        }

        &.purple {
            background: $purple;
        }
    }

    .hovered-btn {
        transform: rotateX(180deg);
        background: $white;
        box-sizing: border-box;
        text-shadow: unset;

        &.blue {
            border: $blue 2px solid;
            color: $blue;
        }

        &.skyblue {
            border: $skyblue 2px solid;
            color: $skyblue;
        }

        &.green {
            border: $green 2px solid;
            color: $green;
        }

        &.orange {
            border: $orange 2px solid;
            color: $orange;
        }

        &.red {
            border: $red 2px solid;
            color: $red;
        }

        &.purple {
            border: $purple 2px solid;
            color: $purple;
        }
    }
}
