$DESTROYER: polygon(
    0% 0%,
    0% 100%,
    70% 100%,
    71% 99%,
    72% 98%,
    73% 97%,
    74% 96%,
    75% 95%,
    76% 94%,
    77% 93%,
    78% 92%,
    79% 91%,
    80% 90%,
    85% 80%,
    90% 70%,
    91% 68%,
    92% 66%,
    93% 64%,
    94% 62%,
    95% 60%,
    100% 50%,
    95% 40%,
    94% 38%,
    93% 36%,
    92% 34%,
    91% 32%,
    90% 30%,
    85% 20%,
    80% 10%,
    79% 9%,
    78% 8%,
    77% 7%,
    76% 6%,
    75% 5%,
    74% 4%,
    73% 3%,
    72% 2%,
    71% 1%,
    70% 0%,
    0% 0%
);

.destroyer .ship,
#destroyer-copy {
    clip-path: $DESTROYER;
    width: 100px;
    @include down($small-desktop) {
        width: 90px;
    }
    @include down($large-tablet) {
        width: 80px;
    }
    @include down($tablet - 1) {
        width: 100px;
    }
    @include down($mobile-xl) {
        width: 90px;
    }
    @include down($mobile) {
        width: 80px;
    }
    @include down(450px) {
        width: 75px;
    }
    @include down($small-mobile) {
        width: 70px;
    }
    @include down(375px) {
        width: 64px;
    }
    @include down($extra-small) {
        width: 57px;
    }
}

// fleet board
.destroyer {
    height: 70px;
    .ship {
        transform: translateX(85px);
    }
    &.vertical {
        height: 120px;
        margin-bottom: 24px;
        .ship {
            transform: rotate(90deg) translate(25px, -75px);
        }
    }
    @include down($small-desktop) {
        height: 65px;
        .ship {
            transform: translateX(90px);
        }
        &.vertical {
            height: 110px;
            .ship {
                transform: rotate(90deg) translate(25px, -82px);
            }
        }
    }
    @include down($large-tablet) {
        height: 60px;
        .ship {
            transform: translateX(100px);
        }
        &.vertical {
            height: 95px;
            .ship {
                transform: rotate(90deg) translate(18px, -90px);
            }
        }
    }
    @include down($tablet - 1) {
        height: 70px;
        .ship {
            transform: translateX(80px);
        }
        &.vertical {
            height: 115px;
            .ship {
                transform: rotate(90deg) translate(25px, -78px);
            }
        }
    }
    @include down($mobile-xl) {
        height: 65px;
        .ship {
            transform: translateX(90px);
        }
        &.vertical {
            height: 105px;
            .ship {
                transform: rotate(90deg) translate(20px, -85px);
            }
        }
    }
    @include down($mobile) {
        height: 60px;
        .ship {
            transform: translateX(100px);
        }
        &.vertical {
            height: 90px;
            .ship {
                transform: rotate(90deg) translate(15px, -85px);
            }
        }
    }
    @include down(450px) {
        height: 57.5px;
        .ship {
            transform: translateX(95px);
        }
        &.vertical {
            height: 85px;
            .ship {
                transform: rotate(90deg) translate(15px, -90px);
            }
        }
    }
    @include down($small-mobile) {
        height: 55px;
        .ship {
            transform: translateX(100px);
        }
        &.vertical {
            height: 85px;
            .ship {
                transform: rotate(90deg) translate(15px, -95px);
            }
        }
    }
    @include down(375px) {
        height: 52px;
        .ship {
            transform: translateX(95px);
        }
        &.vertical {
            height: 75px;
            .ship {
                transform: rotate(90deg) translate(10px, -95px);
            }
        }
    }
    @include down($extra-small) {
        height: 48.5px;
        &.vertical {
            height: 65px;
            .ship {
                transform: rotate(90deg) translate(10px, -103px);
            }
        }
    }
}

// game board
#destroyer-copy {
    &.vertical {
        transform: rotate(90deg) translate(25%, 50%);
    }
}
