.zodiac-wheel {
    min-height: 250px;
    width: 280px;
    margin: 80px auto;
    position: relative;

    img {
        height: 48px;
        opacity: 0.4;
        cursor: pointer;
        &.selected {
            opacity: 1;
        }
        &:hover {
            opacity: 1;
        }
        &.zodiac-1 { //rat
            top: 0%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &.zodiac-2 { //ox
            top: 10%;
            left: 73%;
            transform: translate(-50%, -50%);
        }
        &.zodiac-3 { //tiger
            top: 28%;
            left: 89%;
            transform: translate(-50%, -50%);
        }
        &.zodiac-4 { //rabbit
            left: 94%;
            top: 48%;
            transform: translate(-50%, -50%);
        }
        &.zodiac-5 { //dragon
            top: 72%;
            left: 88%;
            transform: translate(-50%, -50%);
        }
        &.zodiac-6 { //snake
            top: 90%;
            left: 73%;
            transform: translate(-50%, -50%);
        }
        &.zodiac-7 { //horse
            top: 98%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &.zodiac-8 { //sheep
            top: 92%;
            left: 29%;
            transform: translate(-50%, -50%);
        }
        &.zodiac-9 { //monkey
            top: 72%;
            left: 13%;
            transform: translate(-50%, -50%);
        }
        &.zodiac-10 { //rooster
            left: 6%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        &.zodiac-11 { //dog
            top: 27%; 
            left: 15%;
            transform: translate(-50%, -50%);
        }
        &.zodiac-12 { //pig
            top: 8%;
            left: 28%;
            transform: translate(-50%, -50%);
        }
    }

    .year-selector-dropdowns, .date-selector-dropdowns {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        .birth-year-label, .birth-date-label {
            font-size: 12px;
            margin-bottom: 2px;
            display: inline-block;
        }
        #year-selector {
            outline: none;
            width: 75px;
            height: 24px;
            font-size: 18px;
            @media (max-width: $mobile) {
                width: 85px;
            }
        }
        #month-selector, #day-selector {
            outline: none;
            font-size: 18px;
            height: 24px;
        }
    }
    &:before {
        content: '';
        background: $skyblue;
        position: absolute;
        height: 300px;
        width: 300px;
        border-radius: 50%;
        top: 0;
        left: 0;
        transform: translate(-10px, -25px);
        border: 1px $slate solid;
    }
}

.ind-zodiac-container {
    margin: 40px auto;
    padding-bottom: 32px;
    border-bottom: 1px solid $black;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:last-of-type {
        border-bottom: none;
        padding-bottom: 0;
    }
    
    .zodiac-image {
        margin-bottom: 24px;
        width: 300px;
        @media (max-width: $tablet) {
            width: 300px;
        }
        @media (max-width: $mobile-xl) {
            width: 200px;
        }
    }

    .zodiac-name {
        margin-bottom: 10px;
    }

    .zodiac-info {
        h4 {
            margin-bottom: 8px;
            font-size: 16px;
        }
        ul {
            margin-bottom: 16px;
            padding-left: 16px;
        }
        p {
            margin-bottom: 16px;
            font-size: 16px;
            span {
                margin-right: 8px;
                display: inline-block;
                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    .year-container {
        display: flex;
        flex-wrap: wrap;

        .year {
            letter-spacing: 1.05px;
            line-height: 1.35;
        }
    }
}