.slider-board {
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .puzzle-header {
        margin-bottom: 40px;
        @include down($tablet) {
            margin-bottom: 24px;
        }
        .puzzle-author {
            margin-bottom: 24px;
            font-weight: 300;
            font-style: italic;
        }
    }

    .puzzle-fraction {
        margin-bottom: 40px;
        font-size: 20px;
        font-weight: 400;
        @include down($tablet) {
            margin-bottom: 24px;
        }
    }

    .puzzle-buttons-container {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 56px;
        width: 600px;
        justify-content: space-around;
        @include down($tablet) {
            width: 100%;
            margin-bottom: 40px;
            justify-content: space-evenly;
        }

        .outer-container {
            @include down($tablet) {
                display: none;
            }
        }

        .reset-btn,
        .next-btn {
            @include up($tablet) {
                display: none;
            }
        }

        .reset-btn {
            background: $red;
        }
    }

    .slider-main {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
        width: 100%;
        @include down($tablet) {
            flex-direction: column;
        }
    }

    .selection-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 64px;

        .difficulty-container,
        .select-puzzle-container {
            margin-top: 40px;
            @include down($mobile) {
                margin: 40px 12px 0;
            }

            .difficulty-header,
            .select-puzzle-header {
                margin-left: 40px;
                margin-bottom: 16px;
                font-size: 20px;
                @include down($desktop) {
                    margin: 0 24px 12px;
                }
                @include down($mobile) {
                    margin: 0 0 12px;
                }
            }

            .difficulty-labels,
            .select-puzzle-labels {
                display: flex;
                flex-wrap: wrap;
                margin: 0 40px;
                @include down($desktop) {
                    margin: 0 24px;
                }
                @include down($mobile) {
                    margin: 0;
                }

                .label-container {
                    margin-bottom: 12px;
                    padding: 4px;
                    display: flex;
                    align-items: center;
                    width: 120px;
                    cursor: pointer;
                    @include down($tablet) {
                        margin-bottom: 8px;
                    }

                    input {
                        margin-right: 8px;
                    }

                    .difficulty-count {
                        margin-left: 4px;
                    }
                }
            }

            .select-puzzle-labels {
                .label-container {
                    width: 100px;
                    @include down($tablet) {
                        width: 75px;
                    }
                    @include down($mobile-xl) {
                        width: 60px;
                    }
                    @include down($mobile) {
                        width: 55px;
                    }
                }
            }
        }
    }
    .build-your-own-puzzle-text-container {
        margin-top: 48px;
        width: 95%;
        background: $slate;
        padding: 24px;
        border-radius: 8px;
        max-width: 600px;
        cursor: pointer;
        @include down($mobile) {
            padding: 24px 16px;
        }
        @include down($small-mobile) {
            padding: 16px;
        }
        h2 {
            letter-spacing: 1.5px;
            font-size: 20px;
            margin-bottom: 8px;
            @include down($mobile) {
                font-size: 16px;
            }
            @include down($small-mobile) {
                font-size: 14px;
            }
        }
        p {
            color: $white;
            letter-spacing: 1.5px;
            font-size: 16px;
            line-height: 1.33;
            @include down($mobile) {
                font-size: 14px;
            }
            @include down($small-mobile) {
                font-size: 12px;
            }

            .here-text {
                text-decoration: underline;
            }
        }
    }
    
    @import './slider-puzzle-global';
}
