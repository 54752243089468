.wordle-board {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
    @include down($mobile) {
        margin-top: 40px;
    }

    .guesses-container {
        margin-bottom: 24px;

        .guess-row {
            display: flex;
            align-items: center;

            .guess-number {
                margin-right: 8px;
            }

            .guess-letter {
                height: 32px;
                width: 32px;
                background: $white;
                border: 1px solid black;
                border-radius: 2px;
                margin: 2px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                transition: background-color 3s, color 3s, border 3s;
                @include down($mobile) {
                    height: 28px;
                    width: 28px;
                }

                &.opacity {
                    opacity: 0.2;
                    cursor: unset;
                }

                &.letter {
                    color: $white;
                    border: none;
                }

                &.correct {
                    background-color: $green;
                }

                &.incorrect {
                    background-color: $pink;
                }

                &.nothing {
                    background-color: $slate;
                }

                &.flip {
                    animation: flip 1s ease-in-out;
                }
            }
        }
    }

    @keyframes flip {
        0% {
            transform: scaleY(1);
        }

        50% {
            transform: scaleY(0);
            background: $white;
            color: $black;
        }

        100% {
            transform: scaleY(1);
            color: $white;
            &.correct {
                background-color: $green;
            }

            &.incorrect {
                background-color: $pink;
            }

            &.nothing {
                background-color: $slate;
            }
        }
    }
}
