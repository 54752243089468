.war-board {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 64px;
    @include down($mobile) {
        justify-content: space-around;
        margin-top: 40px;
    }

    .player-1-container, .player-2-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 300px;
        min-height: 205.5px;

        &.player-2-container {
            .card-count {
                color: $green;
            }
        }

        .username {
            margin-bottom: 16px;
            @include down($mobile) {
                margin-bottom: 8px;
            }
        }

        .card-count {
            color: $blue;
            font-size: 40px;
            margin-bottom: 24px;
            @include down($mobile) {
                font-size: 32px;
                margin-bottom: 8px;
            }
        }

        .card-container {

        }
    }

    .war-btn-holder {
        display: flex;
        flex-direction: column;

        button {
            &:first-of-type {
                margin-bottom: 16px;
                @include down($mobile) {
                    margin-bottom: 8px;
                }
            }
        }
    }
}