.tabs-container {
    display: flex;
    width: calc(100vw - 112px);

    @include down($large-tablet) {
        width: calc(100vw - 40px);
    }
    @include down($mobile) {
        width: calc(100vw - 32px);
    }

    .tab {
        background: $white;
        padding: 16px;
        border-radius: 16px 16px 0 0;
        text-align: center;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px dashed $slate;
        @include down($mobile) {
            padding: 16px 8px;
        }

        &.selected-tab {
            background: $blue;
            border: none;
            span {
                color: $white;
            }
        }

        input {
            display: none;
        }

        span {
            font-size: 20px;
            letter-spacing: 2px;
            @include down($mobile-xl) {
                font-size: 16px;
                letter-spacing: 1.25px;
            }
            @include down($mobile) {
                font-size: 12px;
                letter-spacing: 1px;
            }
            @include down($extra-small) {
                font-size: 10px;
            }
        }
    }
}