.page {
    width: 100%;
    position: absolute;
    background-color: $white;
    overflow-y: scroll;
    margin-top: 75px;
    height: calc(100vh - 50px);
    animation: fadein 0.5s ease-in;
    opacity: 1;
    // background: $lavender; // just for pictures

    @include down($large-tablet) {
        margin-top: 60px;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    @import "./about";
    @import "./blog";
    @import "./blogs";
    @import "./contact";
    @import "./dashboard";
    @import "./product-detail";
    @import "./products";
    @import "../product-page/styles";
    @import "./resource";
    @import "./resources";
    @import "../resource-page/styles";
    @import "../type-resource-detail-page/style";

    .container {
        min-height: calc(100vh - 359px);
        padding: 56px 56px 80px;
        @include down($large-tablet) {
            padding: 20px 20px 80px;
        }
        @include down($mobile) {
            padding: 16px 16px 80px;
        }

        .heading {
            margin-bottom: 24px;
            text-transform: capitalize;
            letter-spacing: 0.75px;
            font-size: 32px;
            @include down($tablet) {
                padding-left: 16px;
                font-size: 24px;
            }
            @include down($mobile) {
                padding-left: 0;
                margin-bottom: 16px;
                font-size: 16px;
            }
        }

        .breadcrumbs {
            & + .heading {
                margin-top: 50px;
            }
        }
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
