.playing-card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;

    .card-back-container {
        position: relative;
        background: $lightblue;
        border-radius: 4px;
        display: flex;
        border: solid $black 1px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 102px;
        width: 80px;
    }

    .card-back-logo {
        height: 64px;
    }

    .card-front-container {
        position: relative;
        background-color: $white;
        border-radius: 4px;
        display: flex;
        border: solid $black 1px;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .front-corner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        align-items: center;
        &.left {
            left: 6px;
            top: 6px;
        }
        &.right {
            right: 6px;
            bottom: 6px;

            .card-name {
                transform: rotate(180deg);
            }

            .card-suit {
                transform: rotate(180deg);
            }
        }

        .card-name {
            font-size: 10px;
        }

        .card-suit {
            font-size: 14px;
        }
    }

    .card-center-suits-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .face-suits-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100px;
        .center-suit {
            font-size: 48px;
        }
    }

    .numbered-suits-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100px;
        padding: 8px 0;
        position: relative;
        // 7's center and 8's top center
        .floating-suit {
            position: absolute;
            top: 22px;
            left: 50%;
            transform: translateX(-50%);
        }
        // 8's bottom center
        .reversed-floating-suit {
            position: absolute;
            bottom: 22px;
            left: 50%;
            transform: translateX(-50%);
        }
        // 10's top center
        .smaller-floating-suit {
            position: absolute;
            top: 18px;
            left: 50%;
            transform: translateX(-50%);
        }
        // 10's bottom center
        .reversed-smaller-floating-suit {
            position: absolute;
            bottom: 11px;
            left: 50%;
            transform: translateX(-50%);
        }
        // 9's center
        .centered-floating-suit {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .double-suits-column {
            display: flex;
            justify-content: space-evenly;
            width: 60px;
        }
        .single-suit-column {
            display: flex;
            justify-content: center;
        }
        .center-suit {
            font-size: 20px;
        }
        .suit-rotate {
            transform: rotate(180deg);
        }
    }

    .red {
        color: $red;
    }

    .black {
        color: $black;
    }
}
