.about-container {
    min-height: calc(100vh - 300px);

    .about-company {
        margin-bottom: 56px;
        @include down($tablet) {
            margin-bottom: 24px;
        }

        .heading {
            text-transform: unset;
            margin-bottom: 16px;
            font-size: 24px;
            @include down($tablet) {
                font-size: 20px;
            }
            @include down($mobile) {
                font-size: 16px;
                padding-left: 16px;
                padding-right: 16px;
            }
        }

        .about-text {
            @include down($tablet) {
                padding-left: 16px;
                padding-right: 16px;
            }
        }
    }

    .about-text,
    .person-name-and-title,
    .person-title,
    .bio {
        line-height: 130%;
        font-size: 16px;
        @include down($tablet) {
            font-size: 14px;
        }
        @include down($mobile) {
            font-size: 12px;
        }
    }

    .about-people {
        @include down($tablet) {
            display: flex;
            flex-direction: column;
            margin-bottom: 40px;
        }

        .heading {
            text-transform: unset;
            margin-bottom: 16px;
            font-size: 24px;
            @include down($tablet) {
                font-size: 20px;
            }
            @include down($mobile) {
                font-size: 16px;
                padding-left: 16px;
                padding-right: 16px;
            }
        }

        .person-list-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            @include down($tablet) {
                flex-direction: column;
                align-items: center;
            }

            .person-container {
                min-width: calc(50% - 16px);
                max-width: calc(50% - 16px);
                margin-bottom: 40px;
                @include down($tablet) {
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
                @include down($tablet) {
                    width: calc(100% - 32px);
                    min-width: unset;
                    max-width: unset;
                }

                .person-img {
                    width: 100%;
                    margin-bottom: 8px;
                }

                .person-name-and-title {
                    margin-bottom: 8px;
                    font-weight: 300;
                }

                .person-title {
                    font-style: italic;
                }
            }
        }
    }
}
