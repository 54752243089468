.image-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 60%;
    @include down($desktop) {
        width: 100%;
        margin-bottom: 40px;
    }

    .slider-container {
        width: 100%;
        text-align: center;
        overflow: hidden;
        margin: 0 40px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .slider {
            display: flex;
            overflow-x: auto;
            scroll-snap-type: x mandatory;
            scroll-behavior: smooth;
            -webkit-overflow-scrolling: touch;
            scroll-snap-type: mandatory;
            margin-left: 36px;
            margin-right: 36px;
            max-width: 550px;
            @include down($large-tablet) {
                max-width: 450px;
            }
            @include down($mobile) {
                max-width: calc(100vw - 40px);
            }

            &::-webkit-scrollbar {
                width: 10px;
                height: 10px;
            }
            &::-webkit-scrollbar-thumb {
                background: $black;
                border-radius: 10px;
            }
            &::-webkit-scrollbar-track {
                background: transparent;
            }

            & > .slider-img {
                scroll-snap-align: start;
                flex-shrink: 0;
                width: 300px;
                height: 300px;
                margin: 0 40px 8px;
                border-radius: 10px;
                transform-origin: center center;
                transform: scale(1);
                transition: transform 0.5s;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                @include up($desktop) {
                    width: 450px;
                    height: 400px;
                    margin: 0 80px 8px;
                }
            }

            .slider-img {
                height: 400px;
                width: 450px;
                border-radius: 4px;
                @include down($desktop) {
                    width: 500px;
                    height: 400px;
                }
                @include down($large-tablet) {
                    width: 450px;
                    height: 300px;
                }
                @include down($mobile) {
                    width: calc(100vw - 40px);
                    height: 250px;
                }
            }
        }
    }

    .carousel {
        display: flex;
        align-items: center;
        margin-top: 16px;
        @include up($desktop) {
            max-width: 450px;
            overflow-x: scroll;
            margin-left: -100px;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
            &::-webkit-scrollbar {
                display: none;
            }
        }

        &.desktop {
            @include down($desktop) {
                display: none;
            }
            .carousel-img-container {
                &.selected {
                    border: outset 3px $red;
                    border-radius: 8px;
                }

                .carousel-img {
                    height: 40px;
                    width: 40px;
                    border-radius: 4px;
                    padding: 8px;
                    cursor: pointer;

                    @include down($desktop) {
                        width: 75px;
                        height: 75px;
                    }
                }
            }
        }

        &.mobile {
            @include up($desktop) {
                display: none;
            }

            .mobile-carousel-img-container {
                &:not(:last-of-type) {
                    margin-right: 12px;
                }
                &.selected {
                    border: outset 3px $red;
                    border-radius: 100%;
                    padding: 4px;

                    .mobile-carousel-img {
                        background: $red;
                    }
                }

                .mobile-carousel-img {
                    height: 20px;
                    width: 20px;
                    border-radius: 100%;
                    background: $orange;
                    cursor: pointer;
                    @include down($small-mobile) {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }
}
