.feedback-form {
    max-width: 650px;
    display: flex;
    flex-direction: column;
    padding: 40px;
    @include down($large-tablet) {
        padding: 32px 20px;
    }
    @include down($mobile) {
        padding: 16px;
    }

    &.blog {
        padding: 40px;
        @include down($large-tablet) {
            padding: 40px 20px;
        }
        @include down($mobile) {
            padding: 40px 16px;
        }
    }

    .feedback-label {
        margin-bottom: 8px;
    }

    .feedback-input {
        border: 1px solid lightgrey;
        border-radius: 8px;
        max-height: 100px;
        min-height: 100px;
        width: 100%;
        padding: 12px;
        line-height: 1.5;
    }

    .btn-container {
        max-width: 650px;
        width: 100%;
        margin-top: 12px;
        display: flex;
        transform: translateX(-75px);
    }
}
