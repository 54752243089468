.battleship-board {
    display: flex;
    margin-top: 64px;
    @include down($desktop) {
        flex-direction: column;
    }
    @include down($mobile) {
        margin-top: 40px;
    }

    .game-set-up-container {
        margin-left: 12px;
        display: flex;
        @include down($desktop) {
            margin: 40px 0;
        }
        @include down(($tablet - 1)) {
            flex-direction: column;
        }
        @include down($mobile-xl) {
            margin-left: 0;
        }

        .fleet-container {
            margin-right: 12px;
            @include down($tablet - 1) {
                margin-right: 0;
                margin-bottom: 40px;
            }
            h3 {
                margin-bottom: 12px;
                font-size: 16px;
            }
        }
    }

    .game-play-container {
        display: flex;
        @include down($medium-desktop) {
            flex-direction: column;
        }
        @include down($desktop) {
            margin-top: 40px;
        }
        .game-board-container {
            margin-left: 12px;
            @include down($medium-desktop) {
                &:not(:last-of-type) {
                    margin-bottom: 40px;
                }
            }
            @include down($desktop) {
                margin-left: 0;
            }
        }
    }

    .fleet {
        border-radius: 4px;
        background: $lightblue;
        padding: 16px;
        width: 288px;

        .ship-container {
            margin-bottom: 32px;
            &:last-of-type {
                margin-bottom: 16px;
            }

            &.vertical {
                .ship {
                    flex-direction: row;
                }
            }

            .ship-heading-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 8px;
                min-height: 27px;
                .ship-name {
                    font-weight: normal;
                    font-size: 16px;
                }
                .setup-buttons {
                    .green {
                        background: $green;
                    }
                    .blue {
                        background: $blue;
                    }
                    .purple {
                        background: $purple;
                    }
                    .deploy-btn {
                        margin-left: 8px;
                    }
                    .deployed-label {
                        color: $white;
                        letter-spacing: 1.5px;
                        font-weight: bold;
                        text-shadow: 1px 1px 10px black;
                    }
                }
            }
        }
    }

    .game-board-container {
        h3 {
            font-size: 16px;
        }
        .title-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;
            width: 500px;
            @include down($small-desktop) {
                width: 450px;
            }
            @include down($large-tablet) {
                width: 400px;
            }
            @include down($tablet - 1) {
                width: 500px;
            }
            @include down($mobile-xl) {
                width: 450px;
            }
            @include down($mobile) {
                width: 400px;
            }
            @include down(450px) {
                width: 375px;
            }
            @include down($small-mobile) {
                width: 350px;
            }
            @include down(375px) {
                width: 320px;
            }
            @include down($extra-small) {
                width: 285px;
            }
        }
        .game-board {
            background: $lightblue;
            height: 500px;
            width: 500px;
            position: relative;
            @include down($small-desktop) {
                height: 450px;
                width: 450px;
            }
            @include down($large-tablet) {
                height: 400px;
                width: 400px;
            }
            @include down($tablet - 1) {
                height: 500px;
                width: 500px;
            }
            @include down($mobile-xl) {
                height: 450px;
                width: 450px;
            }
            @include down($mobile) {
                height: 400px;
                width: 400px;
            }
            @include down(450px) {
                height: 375px;
                width: 375px;
            }
            @include down($small-mobile) {
                height: 350px;
                width: 350px;
            }
            @include down(375px) {
                height: 320px;
                width: 320px;
            }
            @include down($extra-small) {
                height: 285px;
                width: 285px;
            }
            .board-row {
                display: flex;
                height: 10%;
                min-height: 10%;
                width: 100%;
                border-bottom: solid 1px $slate;
                .board-square {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 10%;
                    height: 100%;
                    min-height: 100%;
                    border: unset;
                    border-right: solid 1px $slate;
                    background: transparent;
                    outline: none;
                    cursor: pointer;
                    position: relative;
                    &:disabled {
                        cursor: not-allowed;
                    }
                    .square-hole {
                        height: 8px;
                        width: 8px;
                        border-radius: 50%;
                        border: 1px solid $black;
                        z-index: 1;
                        @include down($mobile) {
                            height: 6px;
                            width: 6px;
                        }
                        &.hit {
                            background: $red;
                        }
                        &.miss {
                            background: $white;
                        }
                    }
                }
            }
        }
    }

    .sonar {
        position: absolute;
        top: 0%;
        left: 0%;
        transform: translate(50%, 50%);
        width: 100%;
        height: 100%;
        .sonar-center {
            background: $slate;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(-50%, -50%);
        }
        .sonar-circle {
            border: 1px solid $slate;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(-50%, -50%);
        }
        .sonar-circle-inner {
            width: 33.33%;
            height: 33.33%;
        }
        .sonar-circle-middle {
            width: 66.66%;
            height: 66.66%;
        }
        .sonar-circle-outer {
            width: 99.99%;
            height: 99.99%;
        }
    }

    @import "./fleet/ship";
    @import "./fleet/carrier";
    @import "./fleet/battleship";
    @import "./fleet/cruiser";
    @import "./fleet/submarine";
    @import "./fleet/destroyer";
}
