$white: white;
$overlay: rgba(6, 1, 1, 0.2);
$black: rgb(36, 40, 44);
$gray: rgb(158, 160, 162);
$game-hover: #f0f0f0;
$lightgray: lightgray;
$disabled: rgb(219, 215, 215);
$slate: rgb(157, 157, 157);
$darkslate: rgb(100, 100, 100);
$acorn: #B47051;
$beige: beige;
$red: red;
$pink: #ef5d74;
$chartreuse: #f1bebe;
$cherry: #f0cccc;
$salmon: #ffbbbb;
$lightorange: #ecbd8e;
$macaroni: rgb(246, 207, 135);
$sunshine: rgb(244, 182, 67);
$orange: rgb(253, 128, 36);
$lightyellow: #ffffd7;
$morning: #ffffad;
$yellow: #fbef5a;
$lightgreen: #9fffba;
$green: #8cc759;
$darkgreen: #3a5f0b;
$water: #9cd3db;
$lightblue: #9EC5E7;
$skyblue: #0FB5EE;
$blue: #3C98FE;
$lavender: #D3D4E8;
$periwinkle: #b6b9ea;
$purple: #8c6daf;

// guitar color
$guitar-head: #6b4a37;
$guitar-neck: #42280E;
$guitar-body: #f7b219;

// mancala color
$wood: #deb887;

$extra-small: 350px;
$small-mobile: 400px;
$mobile: 500px;
$mobile-xl: 600px;
$tablet: 768px;
$large-tablet: 850px;
$xl-tablet: 900px;
$small-desktop: 940px;
$desktop: 1000px;
$medium-desktop: 1200px;
$large: 1400px;
$very-large: 1800px;
$largest: 2400px;