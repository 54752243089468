.mathlympics-board {
    margin-top: 64px;
    @include down($mobile) {
        margin-top: 40px;
    }

    .settings-container {
        width: 300px;
        margin-bottom: 80px;
        @include down($tablet) {
            margin-bottom: 60px;
        }
        @include down($small-mobile) {
            width: 100%;
        }

        .difficulty-container,
        .show-timer-container,
        .timer-option-container {
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;

            h4 {
                font-size: 20px;
                color: $blue;
                letter-spacing: 1.33px;
                margin-bottom: 12px;
                @include down($mobile) {
                    font-size: 14px;
                    margin-bottom: 8px;
                }
            }
            .options {
                display: flex;
                justify-content: space-around;
                width: 100%;
                .option {
                    padding: 8px;
                    text-align: center;
                    color: $white;
                    letter-spacing: 2.5px;
                    font-size: 16px;
                    
                    &.two {
                        width: 50%;
                    }
                    &.three {
                        width: 33.33%;
                    }
                    &.purple {
                        background: $lavender;
                        &.selected {
                            background: $purple;
                        }
                    }
                    &.orange {
                        background: $lightorange;
                        &.selected {
                            background: $orange;
                        }
                    }
                    &.green {
                        background: $lightgreen;
                        &.selected {
                            background: $green;
                        }
                    }
                    &.blue {
                        background: $lightblue;
                        &.selected {
                            background: $blue;
                        }
                    }
                    &:first-of-type {
                        border-radius: 32px 0 0 32px;
                        margin-right: 2px;
                    }
                    &:last-of-type {
                        border-radius: 0 32px 32px 0;
                        margin-left: 2px;
                    }
                    &:not(:first-of-type, :last-of-type) {
                        margin-left: 2px;
                        margin-right: 2px;
                    }

                    @include down($small-mobile) {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .game-container {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .current-expression {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-bottom: 24px;

            .number-block {
                padding: 16px 24px;
                background: $lightgreen;
                font-size: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 16px;
                color: $orange;
            }
            .operator-block {
                padding: 16px;
                font-size: 40px;
            }
        }

        .guess {
            width: 300px;
            height: 40px;
            padding: 24px 16px;
            background: $white;
            border: 1px solid $black;
            border-radius: 4px;
            margin-bottom: 24px;
            font-size: 32px;
            text-align: right;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        .keypad-container {
            width: 300px;
            .key {
                padding: 8px;
                font-size: 32px;
                background: $orange;
                outline: none;
                border: none;
                border-radius: 8px;
                color: $white;
                width: calc(33.33% - 4px);
                margin-bottom: 6px;
                margin-right: 6px;
                &:nth-of-type(3n + 3) {
                    margin-right: 0;
                }
            }
        }
    }

    .report-container {
        margin-top: 32px;

        h4 {
            margin-bottom: 8px;
        }

        .report-list {
            list-style: none;
            margin-bottom: 40px;

            .report-item {
                margin-bottom: 12px;
                display: flex;
                align-items: center;

                .correct {
                    height: 24px;
                    width: 24px;
                    border-radius: 50%;
                    margin-right: 12px;
                }

                .incorrect {
                    height: 24px;
                    width: 24px;
                    border-radius: 50%;
                    margin-right: 12px;
                }
            }
        }

        .stat-container {
            .statistic-content-container {
                display: flex;
                flex-direction: column;
                margin-bottom: 12px;

                .stat-label {
                    color: $blue;
                }

                span {
                    margin-bottom: 8px;
                }
            }
        }
    }

    .tips-container {
        margin-top: 32px;
        .tip {
            display: flex;
            flex-direction: column;
            &:not(:first-of-type) {
                padding-top: 40px;
                @include down($mobile) {
                    padding-top: 32px;
                }
            }
            &:not(:last-of-type) {
                padding-bottom: 40px;
                border-bottom: 1px solid $black;
                @include down($mobile) {
                    padding-bottom: 32px;
                }
            }

            .tip-heading {
                display: flex;
                align-items: center;
                margin-bottom: 16px;
                font-size: 24px;
                color: $blue;
                letter-spacing: 1.33px;

                @include down($mobile) {
                    font-size: 20px;
                }

                .tip-number {
                    display: inline-flex;
                    margin-right: 8px;
                    padding: 4px 8px;
                    border-radius: 50%;
                    border: 1px solid $black;
                    font-size: 16px;
                }
            }

            .tip-description {
                margin-bottom: 32px;
                font-size: 20px;
                line-height: 1.75;

                &:last-of-type {
                    margin-bottom: 0;
                }

                @include down($mobile) {
                    font-size: 14px;
                    margin-bottom: 20px;
                }

                strong {
                    margin-right: 2px;
                }

                .tip-example {
                    margin-left: 8px;
                    margin-right: 8px;
                    padding: 2px 8px;
                    border: 2px solid $orange;
                    border-radius: 4px;
                }
            }
        }
    }
}
