$SUBMARINE: polygon(
    0% 50%,
    5% 65%,
    10% 75%,
    15% 85%,
    20% 95%,
    25% 100%,
    75% 100%,
    80% 95%,
    85% 85%,
    90% 75%,
    95% 65%,
    98% 55%,
    100% 50%,
    98% 45%,
    95% 35%,
    90% 25%,
    85% 15%,
    80% 5%,
    75% 0.39%,
    25% 0%,
    20% 5%,
    15% 15%,
    10% 25%,
    5% 35%,
    0% 50%
);

.submarine .ship,
#submarine-copy {
    clip-path: $SUBMARINE;
    width: 150px;
    @include down($small-desktop) {
        width: 135px;
    }
    @include down($large-tablet) {
        width: 120px;
    }
    @include down($tablet - 1) {
        width: 150px;
    }
    @include down($mobile-xl) {
        width: 135px;
    }
    @include down($mobile) {
        width: 120px;
    }
    @include down(450px) {
        width: 112.5px;
    }
    @include down($small-mobile) {
        width: 105px;
    }
    @include down(375px) {
        width: 96px;
    }
    @include down($extra-small) {
        width: 85.5px;
    }
}

// fleet board
.submarine {
    height: 70px;
    .ship {
        transform: translateX(55px);
    }
    &.vertical {
        height: 170px;
        margin-bottom: 24px;
        .ship {
            transform: rotate(90deg) translate(50px, -50px);
        }
    }
    @include down($small-desktop) {
        height: 65px;
        .ship {
            transform: translateX(65px);
        }
        &.vertical {
            height: 155px;
            .ship {
                transform: rotate(90deg) translate(45px, -60px);
            }
        }
    }
    @include down($large-tablet) {
        height: 60px;
        .ship {
            transform: translateX(75px);
        }
        &.vertical {
            height: 140px;
            .ship {
                transform: rotate(90deg) translate(40px, -70px);
            }
        }
    }
    @include down($tablet - 1) {
        height: 70px;
        .ship {
            transform: translateX(50px);
        }
        &.vertical {
            height: 170px;
            .ship {
                transform: rotate(90deg) translate(50px, -53px);
            }
        }
    }
    @include down($mobile-xl) {
        height: 65px;
        .ship {
            transform: translateX(65px);
        }
        &.vertical {
            height: 155px;
            .ship {
                transform: rotate(90deg) translate(45px, -60px);
            }
        }
    }
    @include down($mobile) {
        height: 60px;
        .ship {
            transform: translateX(75px);
        }
        &.vertical {
            height: 140px;
            .ship {
                transform: rotate(90deg) translate(40px, -65px);
            }
        }
    }
    @include down(450px) {
        height: 57.5px;
        .ship {
            transform: translateX(70px);
        }
        s &.vertical {
            height: 130px;
            .ship {
                transform: rotate(90deg) translate(35px, -72px);
            }
        }
    }
    @include down($small-mobile) {
        height: 55px;
        .ship {
            transform: translateX(80px);
        }
        &.vertical {
            height: 125px;
            .ship {
                transform: rotate(90deg) translate(35px, -76px);
            }
        }
    }
    @include down(375px) {
        height: 52px;
        &.vertical {
            height: 110px;
            .ship {
                transform: rotate(90deg) translate(30px, -78px);
            }
        }
    }
    @include down($extra-small) {
        height: 48.5px;
        &.vertical {
            height: 100px;
            .ship {
                transform: rotate(90deg) translate(25px, -90px);
            }
        }
    }
}

// game board
#submarine-copy {
    &.vertical {
        transform: rotate(90deg) translate(33%, 100%);
    }
}
