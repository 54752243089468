.product-card {
    border: 1px solid $lightgray;
    border-radius: 4px;
    margin: 16px;
    box-shadow: 0px 0px 10px -5px $black;
    cursor: pointer;
    max-width: 300px;
    min-width: 300px;
    max-height: 425px;
    @include down($largest) {
        max-width: calc(20% - 32px);
        min-width: calc(20% - 32px);
        min-height: 352px;
    }
    @include down($very-large) {
        max-width: calc(25% - 32px);
        min-width: calc(25% - 32px);
    }
    @include down($large) {
        max-width: calc(33.33% - 32px);
        min-width: calc(33.33% - 32px);
    }
    @include down($large-tablet) {
        max-width: calc(50% - 32px);
        min-width: calc(50% - 32px);
        display: flex;
        flex-direction: column;
        min-height: 337px;
    }
    @include down($mobile-xl) {
        width: 100%;
        max-width: 350px;
        margin-left: 0;
        margin-right: 0;
    }
    @include down($mobile) {
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .product-img {
        width: 100%;
        height: 100%;
        max-height: 185px;
        max-width: 100%;
        border-bottom: 0.5px solid $lightgray;
        border-radius: 4px 4px 0 0;
        @include down($mobile-xl) {
            max-height: 200px;
        }
    
        &.img-padding {
            padding: 16px;
            background: $lavender;
        }

        // codebreaker
        &.product-img-7 {
            background: $lightgreen;
        }

        // wordle
        &.product-img-8 {
            background: $lightorange;
        }

        // slider
        &.product-img-9 {
            background: $lightyellow;
        }

        // rebus
        &.product-img-10 {
            background: $lightblue;
        }

        // logic puzzles
        &.product-img-11 {
            background: $lavender;
        }

        // riddles
        &.product-img-12 {
            background: $salmon;
        }

        // jokes
        &.product-img-13 {
            background: $lightblue;
        }
    }

    .copy-container {
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 189px);
        min-height: 162px;
        @include down($large-tablet) {
            height: calc(100% - 203px);
            min-height: 150px;
        }
        @include down($tablet) {
            height: calc(100% - 160px);
        }

        .product-pricing {
            .free-price, .discounted-price, .product-price {
                font-weight: 400;
                font-size: 14px;
                @include down($tablet) {
                    font-size: 12px;
                }
            }

            .free-price {
                color: $pink;
                font-size: 14px;
                @include down($tablet) {
                    font-size: 12px;
                }
                &.two-player {
                    color: $purple;
                }
                &.group {
                    color: $blue;
                }
            }

            .player {
                margin-right: 8px;
                padding: 4px;
                border-radius: 4px;
                font-weight: normal;
                letter-spacing: 1.3px;
                color: $white;
                &.one-player {
                    background: $pink;
                }
                &.two-player {
                    background: $purple;
                }
                &.group {
                    background: $blue;
                }
            }

            .discounted-price {
                text-decoration: line-through;
                margin-right: 8px;
                color: $pink;
            }

            .product-price {
                color: $black;
                font-size: 14px;
                @include down($tablet) {
                    font-size: 12px;
                }
            }
        }
    }

    .product-name {
        margin-bottom: 8px;
        font-size: 14px;
        @include down($tablet) {
            font-size: 12px;
        }
    }

    .product-overview {
        color: $gray;
        line-height: 1.35;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        font-size: 14px;
        @include down($tablet) {
            font-size: 12px;
        }
    }

    .bottom-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .view-btn {
            &.one-player {
                background: $pink;
            }
            &.two-player {
                background: $purple;
            }
            &.group {
                background: $blue;
            }
        }
    }
}

