.secret-number-container {
    margin-top: 64px;
    @include down($mobile) {
        margin-top: 40px;
    }

    .secret-number {
        display: flex;
        margin-bottom: 24px;

        .digit {
            background: $lavender;
            border-radius: 4px;
            height: 75px;
            width: 75px;
            margin-right: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
        }
    }

    .guess-form {
        display: flex;
        margin-bottom: 12px;

        .guess-input {
            padding: 8px;
            margin-right: 8px;
            width: 54px;
            font-size: 16px;
            letter-spacing: 1.5px;
        }
    }
    .guesses-left {
        display: block;
        margin-bottom: 12px;
    }
    .guesses-list-container {
        h4 {
            font-size: 24px;
            margin-bottom: 8px;
        }
        .guesses-list {
            .guess-item {
                display: flex;
                align-items: center;
                font-size: 24px;
                flex-wrap: wrap;
                margin-bottom: 4px;
                &:last-of-type {
                    margin-bottom: 0;
                }

                .guess-number {
                    margin-right: 4px;
                    .number {
                        text-align: right;
                        width: 36px;
                        margin-right: 4px;
                    }
                }

                .comparison-label {
                    border-radius: 4px;
                    padding: 4px 12px;
                    color: $white;
                    margin-left: 6px;
                    margin-right: 6px;
                    &.less {
                        background: $pink;
                    }
                    &.greater {
                        background: $green;
                    }
                }

                .guess {
                    margin-left: 8px;
                    border-radius: 4px;
                    padding: 4px 8px;
                    &.less {
                        box-shadow: inset 0 0 0 2px $pink;
                    }
                    &.greater {
                        box-shadow: inset 0 0 0 2px $green;
                    }
                }
            }
        }
    }
}
