.tic-tac-toe-board {
    display: flex;
    margin-top: 64px;
    @include down($desktop) {
        flex-direction: column;
    }
    @include down($mobile) {
        margin-top: 40px;
    }

    .tic-tac-toe-container {
        width: 300px;
        margin-left: 64px;
        @include down($desktop) {
            margin: 40px 0;
        }
        @include down($mobile-xl) {
            margin-left: 0;
        }
    }

    .score-container {
        margin-bottom: 24px;
        display: flex;

        .score-holder {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            &:first-of-type {
                border-right: 1px solid $black;
            }

            .username {
                color: $blue;
                border-bottom: 1px solid $black;
                width: 100%;
                text-align: center;
                padding: 4px 8px;
                display: flex;
                justify-content: space-between;
            }

            .point {
                padding: 8px 16px;
            }
        }
    }

    .board {
        .row {
            display: flex;
            &:nth-of-type(3n) {
                .square {
                    border-bottom: none;
                }
            }
            .square {
                width: 100px;
                height: 100px;
                border: 1px solid black;
                border-top: none;
                border-left: none;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                font-size: 64px;
                &:hover {
                    background-color: $game-hover;
                }
                &:nth-of-type(3n) {
                    border-right: none;
                }
            }
        }
    }
}