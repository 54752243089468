.breadcrumbs {
    .crumb {
        margin-right: 10px;
        letter-spacing: 0.75px;
        font-size: 16px;
        text-transform: capitalize;
        @include down($small-mobile) {
            font-size: 14px;
        }
        @include down($extra-small) {
            font-size: 12px;
        }

        &.cursor {
            cursor: pointer;
        }
    }

    .angle {
        margin-right: 10px;
        font-size: 16px;
    }
}
