.cooking-type {
    margin-bottom: 8px;
    font-size: 24px;
    color: $skyblue;
    text-align: center;
    @include down($mobile) {
        font-size: 20px;
    }
}

.cooking-tips {
    padding-left: 20px;

    .cooking-tip {
        margin-bottom: 8px;
        vertical-align: center;
        font-size: 20px;
        line-height: 1.3;
        @include down($mobile) {
            font-size: 16px;
        }

        &:last-of-type {
            margin-bottom: 24px;
        }

        .checkmark-logo {
            height: 14px;
            width: 14px;
        }
    }
}

.section-container {
    margin-bottom: 32px;
    width: 500px;
    @include down($tablet) {
        width: 450px;
    }
    @include down($mobile-xl) {
        width: 100%;
    }

    .section-heading {
        margin-bottom: 8px;
        color: $blue;
        font-size: 16px;
        text-align: center;
        @include down($mobile) {
            font-size: 24px;
        }
    }

    .cooking-table {
        margin-bottom: 24px;

        .cooking-table-row {
            display: flex;
            border-bottom: 1px solid $slate;
            border-left: 1px solid $slate;
            border-right: 1px solid $slate;
            &:first-of-type {
                border-top: 1px solid $slate;
            }

            .cooking-table-column {
                width: 50%;
                padding: 12px 16px;
                &:first-of-type {
                    border-right: 1px solid $slate;
                }
            }
        }
    }
}
