@mixin down($size) {
    @media (max-width: $size) {
        @content;
    }
}
@mixin up($size) {
    @media (min-width: ($size + 1)) {
        @content;
    }
}
@mixin between($min, $max) {
    @media (min-width: ($min + 1)) and (max-width: $max) {
        @content;
    }
}