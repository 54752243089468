@keyframes slide-open {
    0% {
        display: flex;
        height: 0px;
        width: 50px;
    }
    100% {
        height: 200px;
    }
}

@keyframes slide-closed {
    0% {
        height: 200px;
    }
    50% {
        height: 100px;
    }
    100% {
        height: 0px;
    }
}

.retail-product-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .product-top-section {
        display: flex;
        margin-top: 80px;
        width: 100%;
        @include down($desktop) {
            align-items: center;
            flex-direction: column;
            margin-top: 48px;
        }

        .details-container {
            width: 40%;
            padding-left: 12px;
            padding-right: 12px;
            @include down($desktop) {
                width: 100%;
                padding-left: 12px;
                padding-right: 12px;
            }
            @include down($mobile) {
                padding-left: 0;
                padding-right: 0;
            }

            .product-name {
                margin-bottom: 12px;
                font-size: 14px;
                @include down($tablet) {
                    font-size: 12px;
                }
            }

            .product-pricing {
                margin-bottom: 24px;

                .free-price, .discounted-price, .product-price {
                    font-weight: 400;
                    font-size: 16px;
                    @include down($large-tablet) {
                        font-size: 16px;
                    }
                }
    
                .free-price {
                    color: $pink;
                    font-size: 14px;
                    @include down($tablet) {
                        font-size: 12px;
                    }
                }
    
                .discounted-price {
                    text-decoration: line-through;
                    margin-right: 8px;
                    color: $pink;
                    font-size: 14px;
                    @include down($tablet) {
                        font-size: 12px;
                    }
                }
    
                .product-price {
                    color: $black;
                    font-size: 14px;
                    @include down($tablet) {
                        font-size: 12px;
                    }
                }
            }

            .product-overview {
                color: $gray;
                margin-bottom: 24px;
                font-size: 14px;
                letter-spacing: 0.75px;
                line-height: 1.25;
                font-weight: 400;
                @include down($tablet) {
                    font-size: 12px;
                }
            }

            .product-transaction {
                display: flex;
                align-items: center;
            }
        }
    }

    .line-break {
        border-bottom: 1px solid $slate;
        padding-bottom: 60px;
        margin-bottom: 60px;
        @include down($desktop) {
            padding-bottom: 40px;
            margin-bottom: 40px;
        }
    }

    .product-bottom-section {
        width: 100%;

        .section-heading {
            font-size: 20px;
            margin-bottom: 24px;
            @include down($large-tablet) {
                font-size: 18px;
            }
        }
    }

    .estimated-sla-container {
        margin-top: 24px;
        font-size: 12px;
        letter-spacing: 1.3px;
        font-style: italic;
    }
}
