.content-container {
    .item-content-container {
        min-height: calc(100vh - 76px);
        display: flex;
        flex-direction: column;
        position: relative;
        @include down($large-tablet) {
            min-height: calc(100vh - 60px);
        }

        .content-title {
            margin: 64px auto;
            font-size: 24px;
            position: relative;
            max-width: 650px;
            width: calc(100vw - 32px);
            @media (max-width: $tablet) {
                width: calc(100vw - 40px);
            }
            @media (max-width: $mobile-xl) {
                font-size: 20px;
            }
            @media only screen and (max-width: $mobile) {
                font-size: 16px;
                margin: 40px auto;
            }

            .back-to-top-container {
                position: absolute;
                bottom: 0;
                right: 0;
                transform: translate(0%, 30%);

                img {
                    width: 40px;
                    height: 40px;
                    transform: rotate(180deg);
                    @include down($mobile) {
                        width: 32px;
                        height: 32px;
                    }
                }
            }
        }

        .content-text-container {
            .content-text {
                font-size: 16px;
                margin-bottom: 16px;
                line-height: 1.35;
                @media (max-width: $tablet) {
                    font-size: 14px;
                }
                @media (max-width: $mobile) {
                    font-size: 12px;
                }

                &.bulleted-list {
                    padding-left: 12px;
                }
            }
        }
    }
}

.learning-tips {
    display: flex;
    flex-direction: column;
    justify-self: center;
    margin: 0 auto;
    max-width: 650px;
    width: calc(100vw - 32px);
    word-wrap: break-word;
    @media (max-width: $tablet) {
        width: calc(100vw - 40px);
    }
}

.btn-indicator {
    border: 2px solid $orange;
    border-radius: 4px;
    padding: 4px 5px;
}

.mnemonic-tip {
    .underline {
        color: $red;
    }
}

.example-indicator {
    padding: 2px 4px;
    border: 2px solid $green;
    border-radius: 4px;
    display: inline-block;
    width: fit-content;
}