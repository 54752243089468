.preview-puzzle-container {
    height: 400px;
    width: 400px;
    background: $black;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    @include down($tablet) {
        height: 256px;
        width: 256px;
    }

    .puzzle-row {
        display: flex;

        .puzzle-item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            position: relative;
            @include down($tablet) {
                width: 32px;
                height: 32px;
            }

            &.block {
                background: $white;
                border: 1px solid $black;
                border-radius: 4px;
            }

            &.dashed-block {
                border: 1px dashed $white;
                background: transparent;
                border-radius: 4px;
            }

            &.border-all {
                background: $yellow;
            }

            &.borderTop {
                &::after {
                    content: "";
                    position: absolute;
                    top: -8px;
                    background: $yellow;
                    height: 16px;
                    width: 100%;
                }
            }

            &.borderRight {
                &::after {
                    content: "";
                    position: absolute;
                    right: -8px;
                    background: $yellow;
                    height: 100%;
                    width: 16px;
                }
            }

            &.borderBottom {
                &::after {
                    content: "";
                    position: absolute;
                    bottom: -8px;
                    background: $yellow;
                    height: 16px;
                    width: 100%;
                }
            }

            &.borderLeft {
                &::after {
                    content: "";
                    position: absolute;
                    left: -8px;
                    background: $yellow;
                    height: 100%;
                    width: 16px;
                }
            }

            &.switch {
                display: flex;
                align-items: center;
                justify-content: center;
                .switch-board {
                    background: $slate;
                    border-radius: 4px;
                    width: 95%;
                    height: 95%;
                    display: flex;
                    .switch-buttons {
                        width: 12px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;
                        align-items: center;
                        .switch-button {
                            width: 8px;
                            height: 6px;
                            border-radius: 8px;
                        }
                        .switch-button-1 {
                            background: $blue;
                        }
                        .switch-button-2 {
                            background: $yellow;
                        }
                        .switch-button-3 {
                            background: $orange;
                        }
                        .switch-button-4 {
                            background: $purple;
                        }
                    }
                    .switch-display {
                        width: calc(100% - 12px);
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .display {
                            width: 90%;
                            height: 12px;
                            background: $white;
                            border-radius: 4px;
                            margin: 4px 0 2px;
                            font-size: 8px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            @include down($mobile) {
                                font-size: 4.5px;
                            }
                        }
                        .additional-buttons {
                            display: flex;
                            width: 90%;
                            height: calc(100% - 20px);

                            .additional-button-container {
                                background: $white;
                                width: 50%;
                                border-radius: 2px;
                                margin: 0 1px 1px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-evenly;
                                .bar {
                                    width: 90%;
                                    height: 2.5px;
                                    background: $red;
                                    border-radius: 2px;
                                    &.red {
                                        background: $red;
                                    }
                                    &.blue {
                                        background: $blue;
                                    }
                                    &.yellow {
                                        background: $yellow;
                                    }
                                    &.green {
                                        background: $green;
                                    }
                                    &.orange {
                                        background: $orange;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .piece {
                background: $green;
                border-radius: 50%;
                z-index: 3;
                width: 50px;
                height: 50px;
                position: absolute;
                @include down($tablet) {
                    width: 32px;
                    height: 32px;
                }

                &.borderTop {
                    border-top: unset;
                }

                &.borderRight {
                    border-right: unset;
                }

                &.borderBottom {
                    border-bottom: unset;
                }

                &.borderLeft {
                    border-left: unset;
                }
            }
        }
    }
}

.arrows-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include down($tablet) {
        margin-top: 40px;
    }
    @include down($mobile) {
        margin-top: 24px;
    }

    .arrow-row {
        display: flex;
        justify-content: space-between;
        width: 200px;
        @include down($mobile) {
            width: 220px;
        }
    }

    .computer-key {
        margin: 2px;
        padding: 8px;
        border: 1px solid $black;
        border-radius: 4px;
        text-transform: uppercase;
        outline: none;
        background: $purple;
        cursor: pointer;
        width: 100px;
        min-width: 100px;
        height: 50px;
        min-height: 50px;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 1.25px;
        color: $white;
        @include down($mobile) {
            height: 40px;
            min-height: 40px;
            margin-bottom: 8px;
        }

        &:disabled {
            cursor: unset;
            opacity: 0.2;
        }

        &.down {
            background: $pink;
        }

        &.up {
            background: $purple;
        }

        &.left {
            background: $skyblue;
        }

        &.right {
            background: $green;
        }
    }
}
