.copy-button {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    cursor: pointer;
    border: none;
    background: transparent;

    .contact-logo {
        height: 20px;
        width: 20px;
        margin-right: 12px;
    }

    .contact-link {
        margin-right: 8px;
        color: $black;
        font-size: 16px;
        @include down($small-mobile) {
            font-size: 12px;
        }
    }

    .copy-img {
        height: 14px;
        width: 14px;
        color: $white;
    }
    .copy-text {
        color: $red;
        font-size: 8px;
    }
}
