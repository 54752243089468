.number-royale-board {
    display: flex;
    margin-top: 64px;
    @include down($desktop) {
        flex-direction: column;
    }
    @include down($mobile) {
        margin-top: 40px;
    }

    .number-royale-container {
        margin-left: 64px;
        @include down($desktop) {
            margin: 40px 0;
        }
        @include down($mobile-xl) {
            margin-left: 0;
        }
    }

    .heading-label {
        margin-bottom: 8px;
    }

    .round-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;

        .round-label {
            margin-bottom: 8px;
            color: $blue;
        }

        .round-table {
            display: flex;
            align-items: center;

            .round-rows {
                display: flex;
                flex-direction: column;

                .round-row {
                    display: flex;
                    margin-bottom: 8px;

                    .username {
                        font-weight: bold;
                        width: 65px;
                    }

                    .number {
                        width: 50px; 
                        text-align: center;

                        &.win {
                            color: $green;
                        }
                        &.lose {
                            color: $red;
                        }
                    }
                }
            }
        }
    }

    .all-numbers-selectors-container {
        display: flex;
        column-gap: 40px;
        margin-bottom: 16px;

        .numbers-selectors-container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .number {
            color: $blue;
            font-size: 40px;
            margin-bottom: 16px;
        }

        .selector {
            width: 60px;
            padding: 4px 8px;
            cursor: pointer;

            .position {
                cursor: pointer;

                &:disabled {
                    background-color: $gray;
                    cursor: not-allowed !important;
                }
            }
        }
    }
}