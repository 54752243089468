.zoomed-img-backdrop {
    position: fixed;
    background: $slate;
    width: 100vw;
    height: 100vh;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
}

.zoomed-img-modal {
    width: 600px;
    height: 400px;
    z-index: 4;
    box-shadow: $black 0px 0px 10px 0px;
    border-radius: 4px;
}

.zoomed-img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
}