.q-and-a-list {
    padding-left: 16px;
}

.q-and-a-item {
    margin-bottom: 24px;
}

.q-and-a-item:last-of-type {
    margin-bottom: 0;    
}

.question {
    font-weight: 400;
    margin-bottom: 12px;
    display: block;
}

.answer { 
    color: $gray;
    line-height: 1.5;
}

.additional-answer {
    margin-top: 16px;
    padding-left: 16px;
}

.additional-answer-list-item {
    margin-bottom: 8px;
    color: $gray;
}

.disclaimer {
    margin-top: 32px;
}