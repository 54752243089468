.scale-chromatic-scale-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 56px auto 0;
    width: 475px;
    @include down($mobile) {
        width: 375px;
    }
    @include down($small-mobile) {
        width: 300px;
    }

    .note {
        font-size: 24px;
        @include down($tablet) {
            font-size: 18px;
        }
    }

    .root-position,
    .second-position,
    .third-position,
    .fourth-position,
    .fifth-position,
    .sixth-position,
    .seventh-position {
        position: relative;
        &:before {
            content: "R";
            position: absolute;
            top: -25px;
            left: 50%;
            transform: translate(-50%, 0);
            color: $red;
        }
    }
    .second-position {
        &:before {
            content: "2";
        }
    }
    .third-position {
        &:before {
            content: "3";
        }
    }
    .fourth-position {
        &:before {
            content: "4";
        }
    }
    .fifth-position {
        &:before {
            content: "5";
        }
    }
    .sixth-position {
        &:before {
            content: "6";
        }
    }
    .seventh-position {
        &:before {
            content: "7";
        }
    }
}

.perfect-fifths-circle {
    position: relative;
    border-radius: 50%;
    border: 1px solid $black;
    height: 250px;
    width: 250px;
    margin: 48px auto 64px;

    .circle-tick {
        height: 25px; 
        width: 2px;
        background: $black;
        font-size: 20px;
        position: absolute;
        @media (max-width: $mobile) {
            font-size: 16px;
            height: 10px;
        }
        &.c-tick {
            left: 50%;
            transform: translate(0, -50%);
            &:before {
                content: 'C';
                position: absolute;
                transform: translate(-50%, -100%);
            }
        }
        &.g-tick {
            left: 75%;
            top: 16.67%;
            transform: translate(0, -150%) rotate(30deg);
            @media (max-width: $mobile) {
                left: 78%;
                top: 5%;
                transform: translate(0, 25%) rotate(30deg);
            }
            &:before {
                content: 'G';
                position: absolute;
                transform: translate(-50%, -100%) rotate(330deg);
            }
        }
        &.d-tick {
            left: 95%;
            top: 30%;
            transform: translate(0, -50%) rotate(60deg);
            @media (max-width: $mobile) {
                left: 93%;
                top: 25%;
                transform: translate(100%, 0%) rotate(60deg);
            }
            &:before {
                content: 'D';
                position: absolute;
                transform: translate(-50%, -100%) rotate(300deg);
                @media (max-width: $mobile) {
                    transform: translate(-50%, -110%) rotate(300deg);
                }
            }
        }
        &.a-tick {
            right: 0%;
            top: 50%;
            transform: translate(50%, 0) rotate(90deg);
            &:before {
                content: 'A';
                position: absolute;
                transform: translate(-50%, -90%) rotate(270deg);
                @media (max-width: $mobile) {
                    transform: translate(-50%, -100%) rotate(270deg);
                }
            }
        }
        &.e-tick {
            left: 93%;
            top: 76%;
            transform: translate(0, -50%) rotate(120deg);
            &:before {
                content: 'E';
                position: absolute;
                transform: translate(-50%, -100%) rotate(240deg);
                @media (max-width: $mobile) {
                    transform: translate(-50%, -125%) rotate(240deg);
                }
            }
        }
        &.b-tick {
            left: 75%;
            top: 93%;
            transform: translate(0, -50%) rotate(150deg);
            &:before {
                content: 'B';
                position: absolute;
                transform: translate(-50%, -100%) rotate(210deg);
                @media (max-width: $mobile) {
                    transform: translate(-50%, -125%) rotate(210deg);
                }
            }
        }
        &.f-sharp-tick {
            left: 50%;
            bottom: 0;
            transform: translate(0, 50%);
            &:before {
                content: 'F#';
                position: absolute;
                transform: translate(-50%, 100%);
                @media (max-width: $mobile) {
                    transform: translate(-50%, 75%);
                }
            }
        }
        &.d-flat-tick {
            left: 25%;
            top: 93%;
            transform: translate(0, -50%) rotate(210deg);
            &:before {
                content: 'Db';
                position: absolute;
                transform: translate(-50%, -110%) rotate(150deg);
                @media (max-width: $mobile) {
                    transform: translate(-50%, -125%) rotate(150deg);
                }
            }
        }
        &.a-flat-tick {
            left: 7%;
            top: 75%;
            transform: translate(0, -50%) rotate(240deg);
            &:before {
                content: 'Ab';
                position: absolute;
                transform: translate(-50%, -110%) rotate(120deg);
                @media (max-width: $mobile) {
                    transform: translate(-50%, -125%) rotate(120deg);
                }
            }
        }
        &.e-flat-tick {
            left: 0%;
            top: 50%;
            transform: translate(-50%, 0) rotate(90deg);
            &:before {
                content: 'Eb';
                position: absolute;
                transform: translate(-50%, 110%) rotate(270deg);
                @media (max-width: $mobile) {
                    transform: translate(-50%, 75%) rotate(270deg);
                }
            }
        }
        &.b-flat-tick {
            left: 7%;
            top: 25%;
            transform: translate(0, -50%) rotate(300deg);
            &:before {
                content: 'Bb';
                position: absolute;
                transform: translate(-50%, -120%) rotate(60deg);
                @media (max-width: $mobile) {
                    transform: translate(-50%, -125%) rotate(60deg);
                }
            }
        }
        &.f-tick {
            left: 25%;
            top: 7%;
            transform: translate(0, -50%) rotate(330deg);
            &:before {
                content: 'F';
                position: absolute;
                transform: translate(-50%, -90%) rotate(30deg);
                @media (max-width: $mobile) {
                    transform: translate(-50%, -100%) rotate(30deg);
                }
            }
        }
    }
}
