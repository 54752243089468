.mancala-board {
    display: flex;
    margin-top: 64px;
    @include down($desktop) {
        flex-direction: column;
    }
    @include down($mobile) {
        margin-top: 40px;
    }

    .mancala-container {
        margin-left: 64px;
        @include down($desktop) {
            display: flex;
            justify-content: center;
            margin-left: 0;
            margin-top: 40px;
        }

        .wooden-board {
            width: 450px;
            height: 200px;
            background: $wood;
            border-radius: 8px;
            display: flex;
            padding: 8px;
            column-gap: 8px;

            @include down($mobile) {
                width: 200px;
                height: 450px;
                flex-direction: column;
                row-gap: 8px;
            }

            .home-hole {
                width: 50px;
                height: 100%;
                box-shadow: inset 1px 1px 8px $black;
                border-radius: 8px;
                padding: 8px;
                display: flex;
                justify-content: space-between;
                flex-direction: column;

                @include down($mobile) {
                    width: 100%;
                    height: 50px;
                    flex-direction: row-reverse;
                    align-items: center;
                    padding: 8px 16px;
                }

                p {
                    display: flex;
                    align-items: center;
                    transform: rotate(270deg) translate(36px, -33px);
                    width: 100px;
                    font-size: 12px;
                    @include down($mobile) {
                        transform: unset;
                        display: flex; 
                        align-items: center;
                    }
                }

                .score {
                    color: $blue;
                    text-shadow: 1px 1px 2px $black;
                    font-size: 32px;
                    margin-left: 16px;
                }

                &:last-of-type {
                    flex-direction: column-reverse;
                    @include down($mobile) {
                        flex-direction: row;
                    }

                    p {
                        display: flex;
                        flex-direction: row-reverse;
                        align-items: center;
                        transform: rotate(90deg) translate(18px, 32px);
                        @include down($mobile) {
                            flex-direction: row;
                            transform: translate(12px, 0);
                        }

                        .score {
                            color: $green;
                            margin-left: 16px;
                            @include down($mobile) {
                                margin-left: 0;
                                margin-right: 16px;
                            }
                        }
                    }
                }
            }

            .holes-container {
                height: 100%;
                width: calc(100% - 100px);
                display: flex;
                flex-direction: column;
                row-gap: 8px;

                @include down($mobile) {
                    width: 100%;
                    height: calc(100% - 100px);
                    flex-direction: row;
                    column-gap: 8px;
                }
            }

            .hole-row {
                display: flex;
                column-gap: 8px;
                height: 50%;
                width: 100%;

                @include down($mobile) {
                    width: 100%;
                    height: 100%;
                    flex-direction: column;
                    row-gap: 6px;
                }
            }

            .hole {
                height: 100%;
                width: 1/6 * 100%;
                box-shadow: inset 1px 1px 8px $black;
                border-radius: 8px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                padding: 8px 6px;

                @include down($mobile) {
                    width: 100%;
                    flex-direction: row;
                    padding: 8px 12px;
                }

                &.selectable {
                    cursor: pointer;
                }

                &.fade-blue {
                    animation: fade-blue infinite 1.5s ease-in-out;
                }
                
                @keyframes fade-blue {
                    from {
                        box-shadow: inset 1px 1px 8px $black;
                    }
                    to {
                        background: $lightblue;
                        box-shadow: inset 1px 1px 8px 2px $blue;
                    }
                }
                
                &.fade-green {
                    animation: fade-green infinite 1.5s ease-in-out;
                }
                
                @keyframes fade-green {
                    from {
                        box-shadow: inset 1px 1px 8px $black;
                    }
                    to {
                        background: $green;
                        box-shadow: inset 1px 2px 8px 2px $green;
                    }
                }
            }

            .stone {
                border-radius: 50%;
                height: 40px;
                width: 32px;

                @include down($mobile) {
                    width: 40px;
                    height: 32px;
                }

                &.water {
                    filter: drop-shadow(0 0 5px $skyblue);
                    &.thirty {
                        background: radial-gradient(#dcf0ef, $water 30%);
                    }
                    &.thirty-five {
                        background: radial-gradient(#dcf0ef, $water 35%);
                    }
                    &.forty {
                        background: radial-gradient(#dcf0ef, $water 40%);
                    }
                    &.forty-five {
                        background: radial-gradient(#dcf0ef, $water 45%);
                    }
                    &.fifty {
                        background: radial-gradient(#dcf0ef, $water 50%);
                    }
                    &.fifty-five {
                        background: radial-gradient(#dcf0ef, $water 55%);
                    }
                    &.sixty {
                        background: radial-gradient(#dcf0ef, $water 60%);
                    }
                    &.sixty-five {
                        background: radial-gradient(#dcf0ef, $water 65%);
                    }
                    &.seventy {
                        background: radial-gradient(#dcf0ef, $water 70%);
                    }
                    &.seventy-five {
                        background: radial-gradient(#dcf0ef, $water 75%);
                    }
                }

                &.macaroni {
                    filter: drop-shadow(0 0 5px $sunshine);
                    &.thirty {
                        background: radial-gradient(#dcf0ef, $macaroni 30%);
                    }
                    &.thirty-five {
                        background: radial-gradient(#dcf0ef, $macaroni 35%);
                    }
                    &.forty {
                        background: radial-gradient(#dcf0ef, $macaroni 40%);
                    }
                    &.forty-five {
                        background: radial-gradient(#dcf0ef, $macaroni 45%);
                    }
                    &.fifty {
                        background: radial-gradient(#dcf0ef, $macaroni 50%);
                    }
                    &.fifty-five {
                        background: radial-gradient(#dcf0ef, $macaroni 55%);
                    }
                    &.sixty {
                        background: radial-gradient(#dcf0ef, $macaroni 60%);
                    }
                    &.sixty-five {
                        background: radial-gradient(#dcf0ef, $macaroni 65%);
                    }
                    &.seventy {
                        background: radial-gradient(#dcf0ef, $macaroni 70%);
                    }
                    &.seventy-five {
                        background: radial-gradient(#dcf0ef, $macaroni 75%);
                    }
                }

                &.cherry {
                    filter: drop-shadow(0 0 5px $chartreuse);
                    &.thirty {
                        background: radial-gradient(#dcf0ef, $cherry 30%);
                    }
                    &.thirty-five {
                        background: radial-gradient(#dcf0ef, $cherry 35%);
                    }
                    &.forty {
                        background: radial-gradient(#dcf0ef, $cherry 40%);
                    }
                    &.forty-five {
                        background: radial-gradient(#dcf0ef, $cherry 45%);
                    }
                    &.fifty {
                        background: radial-gradient(#dcf0ef, $cherry 50%);
                    }
                    &.fifty-five {
                        background: radial-gradient(#dcf0ef, $cherry 55%);
                    }
                    &.sixty {
                        background: radial-gradient(#dcf0ef, $cherry 60%);
                    }
                    &.sixty-five {
                        background: radial-gradient(#dcf0ef, $cherry 65%);
                    }
                    &.seventy {
                        background: radial-gradient(#dcf0ef, $cherry 70%);
                    }
                    &.seventy-five {
                        background: radial-gradient(#dcf0ef, $cherry 75%);
                    }
                }

                &.lavender {
                    filter: drop-shadow(0 0 5px $periwinkle);
                    &.thirty {
                        background: radial-gradient(#dcf0ef, $lavender 30%);
                    }
                    &.thirty-five {
                        background: radial-gradient(#dcf0ef, $lavender 35%);
                    }
                    &.forty {
                        background: radial-gradient(#dcf0ef, $lavender 40%);
                    }
                    &.forty-five {
                        background: radial-gradient(#dcf0ef, $lavender 45%);
                    }
                    &.fifty {
                        background: radial-gradient(#dcf0ef, $lavender 50%);
                    }
                    &.fifty-five {
                        background: radial-gradient(#dcf0ef, $lavender 55%);
                    }
                    &.sixty {
                        background: radial-gradient(#dcf0ef, $lavender 60%);
                    }
                    &.sixty-five {
                        background: radial-gradient(#dcf0ef, $lavender 65%);
                    }
                    &.seventy {
                        background: radial-gradient(#dcf0ef, $lavender 70%);
                    }
                    &.seventy-five {
                        background: radial-gradient(#dcf0ef, $lavender 75%);
                    }
                }

                &.lightgreen {
                    filter: drop-shadow(0 0 5px $periwinkle);
                    &.thirty {
                        background: radial-gradient(#dcf0ef, $lightgreen 30%);
                    }
                    &.thirty-five {
                        background: radial-gradient(#dcf0ef, $lightgreen 35%);
                    }
                    &.forty {
                        background: radial-gradient(#dcf0ef, $lightgreen 40%);
                    }
                    &.forty-five {
                        background: radial-gradient(#dcf0ef, $lightgreen 45%);
                    }
                    &.fifty {
                        background: radial-gradient(#dcf0ef, $lightgreen 50%);
                    }
                    &.fifty-five {
                        background: radial-gradient(#dcf0ef, $lightgreen 55%);
                    }
                    &.sixty {
                        background: radial-gradient(#dcf0ef, $lightgreen 60%);
                    }
                    &.sixty-five {
                        background: radial-gradient(#dcf0ef, $lightgreen 65%);
                    }
                    &.seventy {
                        background: radial-gradient(#dcf0ef, $lightgreen 70%);
                    }
                    &.seventy-five {
                        background: radial-gradient(#dcf0ef, $lightgreen 75%);
                    }
                }

                &.morning {
                    filter: drop-shadow(0 0 5px $lightyellow);
                    &.thirty {
                        background: radial-gradient(#dcf0ef, $morning 30%);
                    }
                    &.thirty-five {
                        background: radial-gradient(#dcf0ef, $morning 35%);
                    }
                    &.forty {
                        background: radial-gradient(#dcf0ef, $morning 40%);
                    }
                    &.forty-five {
                        background: radial-gradient(#dcf0ef, $morning 45%);
                    }
                    &.fifty {
                        background: radial-gradient(#dcf0ef, $morning 50%);
                    }
                    &.fifty-five {
                        background: radial-gradient(#dcf0ef, $morning 55%);
                    }
                    &.sixty {
                        background: radial-gradient(#dcf0ef, $morning 60%);
                    }
                    &.sixty-five {
                        background: radial-gradient(#dcf0ef, $morning 65%);
                    }
                    &.seventy {
                        background: radial-gradient(#dcf0ef, $morning 70%);
                    }
                    &.seventy-five {
                        background: radial-gradient(#dcf0ef, $morning 75%);
                    }
                }
            }
        }
    }
}
