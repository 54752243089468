.instructions-container {
    padding: 56px 40px 40px;
    border-top: 1px solid $slate;
    position: relative;

    .section-heading {
        letter-spacing: 1.5px;
        text-transform: uppercase;
        &:not(:first-of-type) {
            margin-top: 16px;
        }
    }

    .goal {
        margin-top: 12px;
        line-height: 1.33;
    }

    .set-up {
        padding-left: 16px;
        margin-top: 12px;
    }

    .rules {
        padding-left: 16px;
        margin-top: 12px;
    }

    .facts {
        margin-top: 12px;
        list-style: none;

        .item {
            &:not(:last-of-type) {
                margin-bottom: 8px;
            }
        }
    }

    .item {
        margin-bottom: 8px;
        letter-spacing: 1.25px;
        line-height: 1.33;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}