* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: $black;
    font-family: Georgia, Avenir, 'Avenir Light', Arial, Helvetica, sans-serif;
}

.default-btn {
    padding: 6px 10px;
    background: $orange;
    outline: none;
    border: none;
    border-radius: 4px;
    letter-spacing: 1.5px;
    color: $white;
    text-transform: uppercase;
    cursor: pointer;

    &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
}

//////////////////////////////////////////////// FLEX /////////////////////////////////////////////////////////////
.flex {
    display: flex; 
}

.flex-col {
    display: flex;
    flex-direction: column; 
}

.flex-ctr {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-ctr-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.flex-ctr-around {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.flex-ctr-evenly {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.justify-center {
    display: flex;
    justify-content: center;
}

.justify-between {
    display: flex;
    justify-content: space-between;
}

.justify-around {
    display: flex;
    justify-content: space-around;
}

.justify-evenly {
    display: flex;
    justify-content: space-evenly;
}

.justify-end {
    display: flex;
    justify-content: flex-end;
}

.justify-start {
    display: flex;
    justify-content: flex-start;
}

.align-ctr {
    display: flex;
    align-items: center;
}

.align-start {
    display: flex;
    align-items: flex-start;
}

.align-end {
    display: flex;
    align-items: flex-end;
}

.flex-wrap {
    display: flex;
    flex-wrap: wrap;
}

////////////////////////////////////////////// NONE ////////////////////////////////////////////////////////////
.d-none {
    display: none;
}

.mobile-only {
    @include up($tablet) {
        display: none;
    }
}

.none-under-tablet {
    @include down($tablet) {
        display: none;
    }
}

.desktop-only {
    @include down($desktop) {
        display: none;
    }
}

////////////////////////////////////////////// POSITION ///////////////////////////////////////////////////////////
.p-rel {
    position: relative;
}

.p-abs {
    position: absolute;
}

.p-fix {
    position: fixed;
}

////////////////////////////////////////////// TEXT ALIGN ///////////////////////////////////////////////////////////
.text-ctr {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

////////////////////////////////////////////// TEXT DECORATION //////////////////////////////////////////////////////
.underline {
    text-decoration: underline;
}
