.checkers-board {
    display: flex;
    margin-top: 64px;
    @include down($desktop) {
        flex-direction: column;
    }
    @include down($mobile) {
        margin-top: 40px;
    }

    .checkers-container {
        margin-left: 64px;
        @include down($desktop) {
            margin: 40px 0;
        }
        @include down($mobile-xl) {
            margin-left: 0;
        }
    }

    .square {
        &.available-move {
            box-shadow: inset 1px 1px 8px $green;
            background: $green !important;
        }
    }

    .piece {
        width: 80%;
        height: 80%;
        border-radius: 50%;
        background-color: $white;
        box-shadow: 3px 3px 10px $black;
        outline: none;
        border: none;

        &.red {
            background-color: $red;

            &.available-piece {
                animation: grow-red infinite 1.5s ease-in-out;
                cursor: pointer;
                box-shadow: -1px 1px 4px 4px $black;
                @keyframes grow-red {
                    from {
                        box-shadow: -1px 1px 4px 4px $black;
                    }
                    to {
                        box-shadow: -1px 1px 4px 4px $lightgray;
                    }
                }
            }

            .king {
                height: 100%;
                width: 100%;
                mix-blend-mode: color-dodge;
                &.inverted {
                    transform: rotate(180deg);
                }
            }
        }

        &.black {
            background-color: $black;

            &.available-piece {
                animation: grow-black infinite 1.5s ease-in-out;
                cursor: pointer;
                box-shadow: -1px 1px 4px 4px $lightgray;
                @keyframes grow-black {
                    from {
                        box-shadow: -1px 1px 4px 4px $lightgray;
                    }
                    to {
                        box-shadow: -1px 1px 4px 4px $red;
                    }
                }
            }

            .king {
                height: 70%;
                width: 65%;
                mix-blend-mode: overlay;
                &.inverted {
                    transform: rotate(180deg);
                }
            }
        }

        &.selected-piece {
            background: $green;
        }

        &.disabled {
            &.available-piece {
                cursor: not-allowed;
            }
        }
    }
}