.direction-note-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    letter-spacing: 1.33px;
    margin-top: 8px;
    padding: 8px;
    width: 500px;
    @include down($mobile-xl) {
        width: 450px;
    }
    @include down($mobile) {
        width: 375px;
    }
    @include down($small-mobile) {
        width: 290px;
    }

    img {
        height: 24px;
        width: 24px;
        transform: rotate(270deg);
        margin-left: 8px;
    }
}