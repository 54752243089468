.waiting-room-container {
    h3 {
        font-size: 16px;
    }
    .username-container {
        width: 265px;
        margin-top: 12px;
        margin-bottom: 32px;
        .enter-username-input-container {
            display: flex;
            margin-bottom: 4px;
    
            .username-input {
                padding: 8px;
                margin-right: 8px;
                width: calc(100% - 50px);
                font-size: 16px;
                letter-spacing: 1.5px;
            }
        }
        span {
            font-size: 10px;
        }
    }

    .waiting-room {
        width: 120px;
        margin-top: 12px;
        padding: 16px;
        background: $lightgray;
        border-radius: 4px;
        list-style: none;

        .user {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 4px 0;
            font-size: 14px;
            &:hover {
                color: $blue;
            }
            &.is-other {
                cursor: pointer;
            }
            &.selected-user {
                color: $blue;
            }

            .indicator {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                &.available {
                    background: $green;
                }
                &.busy {
                    background: $red;
                }
                &.invited {
                    background: $blue;
                }
            }
        }
    }
}