.Toastify__toast-container--bottom-right {
    position: fixed;
    top: 0;

    .Toastify__toast {
        &.error {
            background: $salmon;
        }

        &.success {
            background: $green;
        }

        .Toastify__toast-body {
            display: flex;
            justify-content: center;
            font-size: 16px;
            text-align: center;
        }

        .Toastify__close-button {
            font-size: 16px;
        }
    }
}
