.connect-4-board {
    display: flex;
    margin-top: 64px;
    @include down($desktop) {
        flex-direction: column;
    }
    @include down($mobile) {
        margin-top: 40px;
    }

    .connect-4-container {
        margin-left: 64px;
        @include down($desktop) {
            margin: 40px 0;
        }
        @include down($mobile-xl) {
            margin-left: 0;
        }
    }

    .score-container {
        margin-bottom: 0px;
        display: flex;
        width: 300px;

        .score-holder {
            width: 50%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px;

            .turn-marker {
                width: 0; 
                height: 0; 
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent; 

                &.red {
                    border-right:10px solid $red;
                }

                &.yellow {
                    border-right:10px solid $yellow;
                }
            }
        }
    }

    .board {
        .pointers {
            display: flex;
            margin-bottom: 12px;
            height: 24px;

            .pointer-holder {
                width: 75px;
                display: flex;
                justify-content: center;

                .pointer {
                    width: 0; 
                    height: 0; 
                    border-left: 20px solid transparent;
                    border-right: 20px solid transparent;

                    &.hovered-red {
                        border-top: 20px solid $red;
                    }
                    &.hovered-yellow {
                        border-top: 20px solid $yellow;
                    }
                }
            }
        }

        .row {
            display: flex;
            &:nth-of-type(6n) {
                .square {
                    border-bottom: 1px solid $black;
                }
            }
            .square {
                position: relative;
                width: 75px;
                aspect-ratio: 1;
                border: 1px solid $black;
                border-bottom: none;
                border-right: none;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                font-size: 64px;
                background: $blue;
                cursor: not-allowed;
                &:nth-of-type(7n) {
                    border-right: 1px solid $black;
                }

                &.hovered {
                    cursor: pointer;
                }

                .center {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background: $white;
                    width: 75%;
                    aspect-ratio: 1;
                    border-radius: 50%;
                    border: 1px solid $black;

                    &.red {
                        background: $red;
                    }

                    &.yellow {
                        background: $yellow;
                    }
                }
            }
        }
    }
}