.periodic-table {
    margin-top: 25px;
    align-items: flex-end;
    width: 100%;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    @include down($mobile) {
        padding: 0;
    }

    .periodic-table-top {
        display: flex;
        width: 100%;
        .periodic-group {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            width: 5.5555%;
            &:first-of-type {
                border-left: 0.25px solid $black;
            } 
            &:nth-of-type(17) {
                .periodic-element {
                    border-right: unset;
                }
            }
            &:last-of-type {
                border-left: 0.25px solid $black;
            }
            &.hide-all-borders {
                border: unset;
            }
            .periodic-element {
                border-right: 0.25px solid $black;  
                border-top: 0.25px solid $black;
                &:last-of-type {
                    border-bottom: 0.25px solid $black;
                }
                &.element-5, &.element-13 {
                    border-left: 0.25px solid $black;
                }
            }
        }
    }
    .periodic-element {
        width: 100%;
        font-size: 10px;
        min-height: 32px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h4 {
            font-size: 12px;
        }
        @media (max-width: $mobile-xl) {
            font-size: 7px;
            min-height: 21px;
            h4 {
                font-size: 7px;
            }
        }
        &.group-reactive-nonmetal {
            background: $skyblue;
        }
        &.group-alkali-metal {
            background: $green;
        }
        &.group-alkaline-earth-metal {
            background: $sunshine;
        }
        &.group-transition-metal {
            background: $lavender;
        }
        &.group-unknown {
            background: $purple;
        }
        &.group-metalloid {
            background: $salmon;
        }
        &.group-post-transition-metal {
            background: $yellow;
        }
        &.group-noble-gas {
            background: $pink;
        }
        &.group-lanthanoid {
            background: $acorn;
        }
        &.group-actinoid {
            background: $blue;
        }
        &.chosen-element {
            box-shadow: 1px 1px 6px 3px $red;
            border: 0.25px solid $black; 
        }
        &.un-chosen-element {
            opacity: 0.25;
            border: 0.25px solid $black; 
        }
        .atomic-number {
            left: 0.5px;
            top: 0.5px;
            position: absolute;
        }
    }
    .periodic-table-bottom {
        display: flex;
        flex-direction: column;
        margin-top: 15px; 
        width: 100%;
        .periodic-row  {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            .periodic-element {
                display: flex;
                flex-direction: column;
                &.element-57, &.element-58, &.element-59, &.element-60, &.element-61, &.element-62, &.element-63, &.element-64, &.element-65, &.element-66, &.element-67, &.element-68, &.element-69, &.element-70, &.element-71 {
                    border-top: 0.25px solid $black;
                }
                min-width: 14.83px;
                width: 5.5555%;
                border-right: 0.25px solid $black;
                border-bottom: 0.25px solid $black;
                &:first-of-type {
                    border-left: 0.25px solid $black;
                }
            }
        }
    }
}