.sinker-board {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
    @include down($mobile) {
        margin-top: 40px;
    }

    .top-container {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 700px;
        @include down($tablet) {
            flex-direction: column;
            align-items: center;
        }

        .tank {
            position: relative;
            width: 200px;
            height: 250px;            
            border: solid 1px $black;
            border-radius: 0 0 8px 8px;

            @include down($tablet) {
                margin-bottom: 32px;
            }

            .water-pipe {
                position: absolute;
                background: $slate;
                opacity: 0.6;
                z-index: 2;
            }
            .long-horizontal-pipe {
                width: 350px;
                height: 6px;
            }
            .vertical-pipe {
                height: 250px;
                width: 6px;
            }
            .short-horizontal-pipe {
                width: 20px;
                height: 6px;
            }
            .pipe-1 {
                bottom: 0;
                left: -354px;
                border-radius: 0 0 4px 0;
            }
            .pipe-2 {
                left: -10px;
                bottom: 6px;
                height: 245px;
            }
            .pipe-3 {
                top: -9px;
                left: -10px;
                border-radius: 4px 4px 0 0;
            }
            .pipe-4 {
                top: -3px;
                left: 4px;
                border-radius: 0 0 0 0px;
            }
            .measurement {
                position: absolute;
                left: 50%;
                transform: translate(-50%, 50%);
                display: flex;
                align-items: center;
                z-index: 2;
                &:before {
                    content: '';
                    width: 35px;
                    height: 1px;
                    background: $black;
                    margin-right: 8px;
                }
                &:after {
                    content: '';
                    width: 35px;
                    height: 1px;
                    background: $black;
                    margin-left: 8px;
                }
                &.measure-5 {
                    bottom: 83.33%;
                }
                &.measure-4 {
                    bottom: 66.67%;
                }
                &.measure-3 {
                    bottom: 50%;
                }
                &.measure-2 {
                    bottom: 33.33%;
                }
                &.measure-1 {
                    bottom: 16.67%;
                }
            }
            .tank-section {
                position: absolute;
                width: 100%;
                height: 16.67%;
                z-index: 1;
                background: transparent;
                &.tank-section-6 {
                    bottom: 83.33%;
                }
                &.tank-section-5 {
                    bottom: 66.67%;
                }
                &.tank-section-4 {
                    bottom: 50%;
                }
                &.tank-section-3 {
                    bottom: 33.33%;
                }
                &.tank-section-2 {
                    bottom: 16.67%;
                }
                &.tank-section-1 {
                    bottom: 0;                       
                }
                &.fill {
                    animation: fill-tank 1.5s ease-in-out;
                    z-index: 1;
                }
                &.water {
                    background: $water;
                }
                @keyframes fill-tank {
                    0% { 
                        height: 1%;
                        background: $water;
                    }
                    100% { 
                        width: 100%;
                        background: $water;
                    };
                }
            }
        }

        .guess-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            .word {
                display: flex;
                align-items: center;
                margin-bottom: 16px;

                .letter {
                    height: 32px;
                    width: 32px;
                    background: $white;
                    border: 1px solid black;
                    border-radius: 2px;
                    margin: 2px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-transform: uppercase;
                    transition: background-color 3s, color 3s, border 3s;
                    @include down($mobile) {
                        height: 28px;
                        width: 28px;
                    }
                    @include down($small-mobile) {
                        height: 24px;
                        width: 24px;
                        font-size: 14px;
                    }
                    @include down($extra-small) {
                        height: 20px;
                        width: 20px;
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .bottom-container {
    }
}
